import { AdminAccount, AdminPost, LibraryCategory, AdminPopup } from 'components';
import AdminLibrary from 'components/AdminLibrary';
import AdminPopupEdit from 'components/AdminPopupEdit';
import { adminAPi, categoryAPi } from 'libs/api';
import { cls } from 'libs/utils';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

function Admin() {
  const location = useLocation();
  const navigation = useNavigate();
  const [userInput, setUserInput] = useState('');
  const curIndex = +location?.search?.split('?')[1].split('=')[1];
  const curTab =
    location.pathname.split('/')[location.pathname.split('/').length - 1];
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem('jwtToken'));
  const { data: userData, isLoading: userLoading } = useQuery(
    ['user', curIndex - 1, jwtToken],
    adminAPi.adminUser,
    { enabled: curTab === 'account' }
  );
  const { data: postData, isLoading: postLoading } = useQuery(
    ['post', curIndex - 1, jwtToken],
    adminAPi.adminPost,
    { enabled: curTab === 'post' }
  );
  const { data: contentData, isLoading: contentLoading } = useQuery(
    ['content', curIndex - 1, jwtToken],
    adminAPi.adminLib,
    { enabled: curTab === 'library' }
  );

  const { data: categoryData, isLoading: categoryLoading } = useQuery(
    'category',
    categoryAPi.categoryList
  );
  
  const { data: popupData, isLoading: popupLoading } = useQuery(
    ['popup', curIndex - 1, jwtToken],
    adminAPi.adminPopup,
    { enabled: curTab === 'popup' }
  );

  const [MAX_NUM, setMAX_NUM] = useState(0);

  const isLoading =
    userLoading || postLoading || contentLoading || categoryLoading || popupLoading;

  const onTabClick = (path: string) => {
    navigation({
      pathname: `/acupediaAdmin/${path}`,
      search: '?index=1',
    });
  };
  const onLeftClick = () => {
    if (+location?.search?.split('?')[1].split('=')[1] === 1) {
      return;
    }
    navigation({
      search: `?index=${+location?.search?.split('?')[1].split('=')[1] - 1}`,
    });
  };
  const onRightClick = () => {
    if (+location?.search?.split('?')[1].split('=')[1] === MAX_NUM) {
      return;
    }
    navigation({
      search: `?index=${+location?.search?.split('?')[1].split('=')[1] + 1}`,
    });
  };

  const refreshJwtToken = () => {
    setJwtToken(sessionStorage.getItem('jwtToken'));
  };

  useEffect(() => {
    window.addEventListener('storage', refreshJwtToken);

    return () => {
      window.removeEventListener('storage', refreshJwtToken);
    };
  }, []);

  useEffect(() => {
    if (!jwtToken) {
      navigation('/');
    }
  }, [jwtToken]);

  useEffect(() => {
    if (curTab === 'post') {
      setMAX_NUM(
        Math.floor(
          postData?.data.totalCount % 20 === 0
            ? postData?.data.totalCount / 20
            : postData?.data.totalCount / 20 + 1
        )
      );
    } else if (curTab === 'user') {
      setMAX_NUM(
        Math.floor(
          userData?.data.totalCount % 20 === 0
            ? userData?.data.totalCount / 20
            : userData?.data.totalCount / 20 + 1
        )
      );
    } else if (curTab === 'library') {
      setMAX_NUM(
        Math.floor(
          contentData?.data.totalCount % 20 === 0
            ? contentData?.data.totalCount / 20
            : contentData?.data.totalCount / 20 + 1
        )
      );
    } else if (curTab === 'popup') {
      setMAX_NUM(
        Math.floor(
          popupData?.data.totalCount % 20 === 0
            ? popupData?.data.totalCount / 20
            : popupData?.data.totalCount / 20 + 1
        )
      );
    }
  }, [curTab, isLoading]);

  return isLoading ? null : (
    <div
      className={cls(isMobile ? 'w-[1440px]' : 'w-full ', ' flex bg-[#ededed]')}
    >
      {/* 왼쪽 탭 */}
      <div
        className={cls(
          isMobile ? 'w-[185px]' : 'w-[300px]',
          ' flex flex-col items-center bg-white '
        )}
      >
        <div className="mb-[26px] pl-3 h-[26px] border-l-2 border-[#0A2996] mt-[49px] text-[19px] font-semibold">
          관리자 설정
        </div>
        <div
          className={cls(
            curTab === 'account'
              ? 'bg-[#0A2996] text-white'
              : 'text-[#0A2996] text-opacity-50',
            'cursor-pointer text-[15px] w-full h-[49px] border-[1px] border-[#CECED0] flex justify-center items-center'
          )}
          onClick={() => onTabClick('account')}
        >
          계정 관리
        </div>
        <div
          onClick={() => onTabClick('post')}
          className={cls(
            curTab === 'post'
              ? 'bg-[#0A2996] text-white'
              : 'text-[#0A2996] text-opacity-50',
            'cursor-pointer text-[15px] w-full h-[49px] border-[1px] border-[#CECED0] flex justify-center items-center'
          )}
        >
          위키글 관리
        </div>
        <div
          className={cls(
            curTab === 'library'
              ? 'bg-[#0A2996] text-white'
              : 'text-[#0A2996] text-opacity-50',
            'cursor-pointer text-[15px] w-full h-[49px] border-[1px] border-[#CECED0] flex justify-center items-center'
          )}
          onClick={() => onTabClick('library')}
        >
          학술 자료庫 관리
        </div>
        <div
          className={cls(
            curTab === 'popup'
              ? 'bg-[#0A2996] text-white'
              : 'text-[#0A2996] text-opacity-50',
            'cursor-pointer text-[15px] w-full h-[49px] border-[1px] border-[#CECED0] flex justify-center items-center'
          )}
          onClick={() => onTabClick('popup')}
        >
          팝업창 관리
        </div>
      </div>
      <div className="bg-[#ededed] flex-1 flex flex-col items-center">
        {/* 메인 */}
        <div
          className={cls(
            isMobile ? 'w-[1243px] ' : 'w-[1243px]',
            'bg-white flex mx-4 mt-[50px]'
          )}
        >
          {curTab === 'account' ? (
            <AdminAccount data={userData} />
          ) : curTab === 'post' ? (
            <AdminPost data={postData} />
          ) : curTab === 'library' ? (
            <AdminLibrary data={contentData} />
          ) : curTab === 'popup' ? (
            <AdminPopup data={popupData} />
          ) : curTab === 'popupEdit' ? (
            <AdminPopupEdit />
          ) : (
            <LibraryCategory data={categoryData} />
          )}
        </div>
        {/* 페이지 넘김 */}
        {curTab === 'popupEdit' ? null : (
          <div className="w-[140px] h-[29px] bg-[#EBF2F8] mt-7 mb-[207px] flex items-center">
          <div
                onClick={onLeftClick}
              className={cls(
                curIndex === 1 ? 'opacity-20' : 'cursor-pointer',
                'flex-1 justify-center flex'
              )}
            >
              {'< Prev'}
            </div>
            <div
              onClick={onRightClick}
              className={cls(
                curIndex === MAX_NUM ? 'opacity-20' : 'cursor-pointer ',
                'flex-1 justify-center flex'
              )}
            >
              {'Next >'}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Admin;
