import { useEffect, useState, useRef } from 'react';
import {
  AcupointsItem,
  PrescriptionItem,
  SearchItem,
  WikiItem,
} from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { searchApi } from 'libs/api';
import { useAppDispatch } from 'store';
import settingSlice from 'slices/setting';
import LibraryItem from 'components/LibraryItem';
import SearchItemPrescription from 'components/SearchItemPrescription';

function SearchResult() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem('jwtToken'));
  const curSearch = decodeURI(location.search.split('=')[1]);
  const curSearchdecode = decodeURIComponent(location.search.split('=')[1]);
  const [highlight, setHighlight] = useState(curSearch);
  const curLocation = location.pathname + location.search;
  const { data, isLoading } = useQuery(
    ['search', curSearch],
    searchApi.searchWord
  );
  // console.log(curSearch);
  console.log(data);

  const onWikiClick = () => {
    navigation({
      pathname: '/wiki',
      search: `?search=${curSearch}&index=1`,
    });
  };
  const onAcupointsClick = () => {
    navigation({
      pathname: '/acupoints',
      search: `?search=${curSearch}&index=1`,
    });
  };
  const onLibraryClick = () => {
    navigation({
      pathname: '/library',
      search: `?search=${curSearch}&index=1`,
    });
  };
  const onPrescriptionClick = () => {
    navigation({
      pathname: '/prescription',
      search: `?search=${curSearch}&index=1`,
    });
  };
  const onNewWikiClick = () => {
    dispatch(
      settingSlice.actions.setLastPageBeforeLogin({
        lastPageBeforeLogin: curLocation,
      })
    );
    navigation({
      pathname: jwtToken ? '/wikiEdit' : '/login',
      search: jwtToken ? '?title=newWiki' : '',
    });
  };

  const refreshJwtToken = () => {
    setJwtToken(sessionStorage.getItem('jwtToken'));
  };

  useEffect(() => {
    window.addEventListener('storage', refreshJwtToken);

    return () => {
      window.removeEventListener('storage', refreshJwtToken);
    };
  }, []);

  useEffect(() => {
    setHighlight(curSearch);
  }, [curSearch]);

  const WikiRef = useRef<HTMLDivElement>(null);
  const LibraryRef = useRef<HTMLDivElement>(null);
  const AcupointRef = useRef<HTMLDivElement>(null);
  const PrescriptionRef = useRef<HTMLDivElement>(null);

  const SeeAllResult = () => {
    window.scrollTo(0, 0);
  };

  const onWikiRefClick = () => {
    WikiRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const onLibraryRefClick = () => {
    LibraryRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const onAcupointRefClick = () => {
    AcupointRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const onPrescriptionRefClick = () => {
    PrescriptionRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div className="w-[1000px] space-y-4 mb-[347px]">
      <div className="flex h-[35px] space-x-9 text-[20px] cursor-pointer tracking-tighter">
        <div
          onClick={SeeAllResult}
          className="hover:text-blue-600 hover:font-semibold hover:border-b-2 border-blue-600"
        >
          전체
        </div>
        <div
          onClick={onWikiRefClick}
          className="hover:text-blue-600 hover:font-semibold hover:border-b-2 border-blue-600"
        >
          위키항목
        </div>
        <div
          onClick={onLibraryRefClick}
          className="hover:text-blue-600 hover:font-semibold hover:border-b-2 border-blue-600"
        >
          학술 자료庫
        </div>
        <div
          onClick={onAcupointRefClick}
          className="hover:text-blue-600 hover:font-semibold hover:border-b-2 border-blue-600"
        >
          경혈주치
        </div>
        <div
          onClick={onPrescriptionRefClick}
          className="hover:text-blue-600 hover:font-semibold hover:border-b-2 border-blue-600"
        >
          침구처방
        </div>
      </div>
      {isLoading ? null : (
        <>
          <div className="scroll-mt-[76px] mt-[30px]" ref={WikiRef}>
            <SearchItem title="위키항목" subtitle="Acupedia Wiki">
              <>
                {data.data.hasExactlyMath ? null : (
                  <div className="flex items-center ">
                    <div className="text-[15px] text-[#1D80D6] font-[600] w-[300px] bg-[#FFFCE4] h-[30px]">
                      <span
                        onClick={() => {
                          navigation({
                            pathname: '/wikiDetail',
                            search: `?title=${curSearch}`,
                          });
                        }}
                        className="cursor-pointer"
                      >
                        {location.pathname !== '/wiki' ? (
                          <span className="text-[18px]">{'·  '}</span>
                        ) : null}
                        <span className="ml-[15px]  border-b-[3px] border-[#1D80D6] text-[15px] ">
                          {curSearchdecode}
                        </span>
                      </span>
                    </div>
                    <span className="w-[225px] text-[15px] bg-[#FFFCE4] h-[30px] pt-2">
                      {'작성된 위키가 없습니다.'}
                    </span>
                    <span className="text-[15px] bg-[#FFFCE4] h-[30px] flex-1">
                      {''}
                    </span>
                  </div>
                )}
                {data.data.wikis.slice(0, 4).map((item: any, index: number) => {
                  return <WikiItem key={index} item={item} />;
                })}
              </>

              {/* {data.data.wikis.length === 0 ? (
              <div className="mt-4">
                <span className="text-[15px] text-[#9AA6B0]">
                  생성된 위키 항목이 없습니다.
                </span>
                <span
                  onClick={onNewWikiClick}
                  className="cursor-pointer ml-[104px] text-[15px] text-[#188AEF] "
                >
                  {'[새 위키 작성하기]'}
                </span>
              </div>
            ) : (
              data.data.wikis.slice(0, 4).map((item: any, index: number) => {
                return <WikiItem key={index} item={item} />;
              })
            )} */}
            </SearchItem>
          </div>
          {data.data.wikis.length === 0 ? null : (
            <div className="pb-14 flex justify-end  text-[#1D80D6] text-[15px]">
              <span className="cursor-pointer" onClick={onWikiClick}>
                {'[more..]'}
              </span>
            </div>
          )}
          <div className="h-[1px] bg-[#d8dce0]" />

          <div className="scroll-mt-[76px]" ref={LibraryRef}>
            <SearchItem title="학술 자료庫" subtitle="Acupedia Library">
              {data.data.libraries.length === 0 ? (
                <div className="mt-4">
                  <span className="text-[15px] text-[#9AA6B0]">
                    작성된 학술 자료庫가 없습니다.
                  </span>
                </div>
              ) : (
                data.data.libraries
                  .slice(0, 4)
                  .map((item: any, index: number) => {
                    return (
                      <LibraryItem
                        key={index}
                        item={item}
                        highlight={highlight}
                      />
                    );
                  })
              )}
            </SearchItem>
          </div>

          <div className="pb-14 flex justify-end  text-[#1D80D6] text-[15px]">
            {data.data.libraries.length === 0 ? null : (
              <span className="cursor-pointer" onClick={onLibraryClick}>
                {'[more..]'}
              </span>
            )}
          </div>

          <div className="mt-[1px] h-[1px] bg-[#d8dce0]" />

          <div className="scroll-mt-[76px]" ref={AcupointRef}>
            <SearchItem title="경혈주치" subtitle="Acupoint indication">
              {data.data.acupoints.length === 0 ? (
                <div className="mt-4" ref={AcupointRef}>
                  <span className="text-[15px] text-[#9AA6B0]">
                    경혈주치 검색 결과가 없습니다.
                  </span>
                </div>
              ) : (
                data.data.acupoints
                  .slice(0, 3)
                  .map((item: any, index: number) => {
                    return (
                      <AcupointsItem
                        key={index}
                        highlight={highlight}
                        item={item}
                      />
                    );
                  })
              )}
            </SearchItem>
          </div>
          <div className="pb-14 flex justify-end text-[#1D80D6] text-[15px]">
            {data.data.acupoints.length === 0 ? null : (
              <span className="cursor-pointer" onClick={onAcupointsClick}>
                {'[more..]'}
              </span>
            )}
          </div>
          <div className="mt-[1px] h-[1px] bg-[#d8dce0]" />
          <div className="scroll-mt-[76px]" ref={PrescriptionRef}>
            <SearchItemPrescription
              title="대혈 및 침구처방"
              subtitle="Acupoints combination & Acupuncture prescription"
            >
              {!data.data.prescriptions.quotes ? (
                <div className="mt-4">
                  <span className="text-[15px] text-[#9AA6B0]">
                    대혈 및 침구처방 검색 결과가 없습니다.
                  </span>
                </div>
              ) : (
                data.data.prescriptions.quotes
                  .slice(0, 3)
                  .map((item: any, index: number) => {
                    return (
                      <PrescriptionItem
                        key={index}
                        highlight={highlight}
                        item={item}
                      />
                    );
                  })
              )}
            </SearchItemPrescription>
          </div>
          <div className="flex justify-end text-[#1D80D6] text-[15px]">
            {!data.data.prescriptions.quotes ? null : (
              <span className="cursor-pointer" onClick={onPrescriptionClick}>
                {'[more..]'}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default SearchResult;
