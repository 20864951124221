import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BiSearchAlt2 } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';
import { useForm } from 'react-hook-form';
import { GiHamburgerMenu } from 'react-icons/gi';
import { isBrowser, isMobile } from 'react-device-detect';
import Menu from './Menu';
import useComponentVisible from 'libs/useComponentVisible';
import settingSlice from 'slices/setting';
import { useAppDispatch } from 'store';
import SignUpModal from './SignUpModal';
import { toast } from 'react-toastify';

interface IHeader {
  buttonTitle?: string;
}

function Header({ buttonTitle }: IHeader) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  const jwtToken = sessionStorage.getItem('jwtToken');
  const { register, handleSubmit, reset, watch, setValue } = useForm();
  const [isEmptyWord, setIsEmptyWord] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const searchObserver = watch();
  const curLocation = location.pathname + location.search;
  console.log(location);
  const curSearch = location.search.split('&')[0].split('=')[1]
    ? decodeURIComponent(location.search.split('&')[0].split('=')[1])
    : '';

  const onLogoClick = () => {
    navigation('/');
  };
  const onButtonClick = () => {
    if (buttonTitle === '회원가입') {
      setIsModalOpen(true);

      // navigation('/createAccount');
    } else {
      dispatch(
        settingSlice.actions.setLastPageBeforeLogin({
          lastPageBeforeLogin: curLocation,
        })
      );
      navigation('/login');
    }
  };

  const onValid = (validForm: any) => {
    if (validForm.search.trim().length === 0) return;
    if (
      /[\{\}\[\]\/?.|\*~`!^\_+<>@\#$%&\\\=\'\"]/g.test(validForm.search.trim())
    ) {
      if (isMobile) {
        alert('특수문자를 포함할 수 없습니다.');
      } else {
        toast('특수문자를 포함할 수 없습니다.');
      }
      return;
    }
    console.log(encodeURIComponent(validForm.search));
    // reset();
    navigation({
      pathname: 'searchResult',
      search: `?search=${encodeURIComponent(validForm.search)}`,
    });
  };

  const [isMenuRightVisible, setIsMenuRightVisible] = useState(false);

  const onMenuClick = () => {
    setIsMenuRightVisible((prev) => !prev);
  };

  // const onMenuClick = () => {
  //   setIsComponentVisible(true);
  // };

  useEffect(() => {
    if (searchObserver?.search?.length > 0) {
      setIsEmptyWord(false);
    } else {
      setIsEmptyWord(true);
    }
  }, [searchObserver]);

  useEffect(() => {
    if (/[\{\}\[\]\/?.|\*~`!^\_+<>@\#$%&\\\=\'\"]/g.test(curSearch.trim())) {
      if (isMobile) {
        alert('특수문자를 포함할 수 없습니다.');
      } else {
        toast('특수문자를 포함할 수 없습니다.');
      }
      navigation('/');
      return;
    }
    setValue('search', curSearch);
  }, []);

  return (
    <Container>
      <Content>
        <Logo onClick={onLogoClick}>ACUPEDIA</Logo>
        <InputWrap>
          <form onSubmit={handleSubmit(onValid)}>
            <input
              {...register('search')}
              placeholder="검색어를 입력해주세요."
            />
          </form>
          {isEmptyWord ? null : (
            <IoClose
              onClick={() => reset()}
              className="close"
              color="white"
              fontWeight={'bold'}
            />
          )}
          <div
            onClick={handleSubmit(onValid)}
            className="cursor-pointer absolute flex items-center right-0 mr-[20px]"
          >
            <BiSearchAlt2 className="mr-1 text-[18px]" color="#CFD9FE" />
            <span className="text-[#CFD9FE] font-black">검색</span>
          </div>
        </InputWrap>
        {jwtToken || !buttonTitle ? (
          <div className="relative ml-auto mr-[57px]">
            <GiHamburgerMenu
                  onClick={onMenuClick}
                  className="menu"
                  color="white"
                />
                {isMenuRightVisible ? (
                  <Menu
                    setIsMenuRightVisible={setIsMenuRightVisible}
                  />
                ) : null}            
          </div>
        ) : (
          <Button onClick={onButtonClick}>{buttonTitle}</Button>
        )}
      </Content>
      <SignUpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </Container>
  );
}

export default Header;

const Container = styled.nav`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: ${isBrowser ? '' : '1440px'};
  display: flex;
  /* min-width: 1440px; */
  height: 76px;
  background-color: ${(props) => props.theme.main};
  justify-content: center;
  z-index: 100;

  .search {
    position: absolute;
    right: 0;
    margin-right: 53px;
  }
  .close {
    position: absolute;
    right: 0;
    margin-right: 92px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .menu {
    width: 32px;
    height: 24px;
    cursor: pointer;
  }
`;
const Content = styled.div`
  width: 1440px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;
const Logo = styled.div`
  width: 246px;
  height: 70px;
  color: white;
  font-size: 40px;
  font-weight: 600;
  margin-left: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const InputWrap = styled.div`
  width: 515px;
  height: 48px;
  margin-left: 25px;
  display: flex;
  /* margin-left: auto;
  margin-right: auto; */
  align-items: center;
  border-bottom: 1px solid white;
  position: relative;

  input {
    width: 400px;
    height: 100%;
    border: none;
    background-color: inherit;
    margin-left: 22px;
    color: white;

    ::placeholder {
      color: white;
    }
    &:focus {
      outline: none;
      background-color: inherit;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white !important;
  }
`;
const Button = styled.div`
  width: 107px;
  height: 46px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  margin-left: auto;
  margin-right: 57px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
`;
