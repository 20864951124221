import dayjs from 'dayjs';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  item: any;
}

function WikiItem({ item }: Props) {
  const location = useLocation();
  const navigation = useNavigate();
  const time = dayjs(item.lastUpdatedAt).format('YYYY-MM-DD hh:mm:ss');

  const onWikiClick = () => {
    navigation({
      pathname: '/wikiDetail',
      search: `?title=${item.wikiTitle}`
    });
  };

  return (
    <div className="flex items-center ">
      <div className="text-[15px] text-[#1D80D6] font-[600] w-[300px]  pr-4">
        <div onClick={onWikiClick} className="cursor-pointer  flex">
          {location.pathname !== '/wiki' ? (
            <span className="text-[18px]">{'·  '}</span>
          ) : null}
          <div className=" ml-[15px]">
            <span className=" border-b-[3px] border-[#1D80D6] text-[15px] ">
              {item.wikiTitle.replace(/ /g, '\u00a0')}
            </span>
          </div>
        </div>
      </div>
      <span className="w-[225px] text-[15px]">{item.lastModifierNickname}</span>
      <span className="text-[15px]">{time}</span>
    </div>
  );
}

export default WikiItem;
