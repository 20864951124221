import Axios from 'axios';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

const axiosInstance = Axios.create({
  baseURL: 'https://zmffkdnemgus.xyz'
});
axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      if (isMobile) {
        alert('네트워크 에러');
      } else {
        toast.error('네트워크 에러');
      }
    } else {
      // const code = error.response.status;
      // const response = error.response.data;
      // const originalRequest = error.config;
      // console.log(error.response.data.data);
      console.log(error.response);

      if (error.response.data.data.message) {
        if (isMobile) {
          alert(error.response.data.data.message);
        } else {
          toast.error(error.response.data.data.message);
        }
      } else {
        if (isMobile) {
          alert('오류가 발생하였습니다.');
        } else {
          toast.error('오류가 발생하였습니다.');
        }
      }

      return Promise.reject(error);
    }
  }
);
export default axiosInstance;
