import React from 'react';

interface SearchProps {
  title: string;
  subtitle: string;
  children: React.ReactNode;
}

function SearchItem({ title, subtitle, children }: SearchProps) {
  return (
    <div className="mb-[60px]">
      <div className="flex w-full h-full">
        <div className="border-l-[#0F43F9] border-l-[4px] w-[293px] h-fit ">
          <p className="indent-5 text-[25px] font-semibold ">{title}</p>
          <p className="indent-5 text-[14px] ">{subtitle}</p>
        </div>

        <div className="w-full h-full ml-[60px] space-y-[30px]">{children}</div>
      </div>
    </div>
  );
}

export default SearchItem;
