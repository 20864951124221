import { ChangeInfo, ChangePassword, Exit } from 'components';
import { cls } from 'libs/utils';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Mypage() {
  const navigation = useNavigate();
  const [curIndex, setCurIndex] = useState(0);

  const onIndexClick = (number: number) => {
    setCurIndex(number);
  };
  const onBackClick = () => {
    navigation('/');
  };

  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem('jwtToken'));
  const [nickname, setNickname] = useState(sessionStorage.getItem('nickname'));
  const refreshJwtToken = () => {
    setJwtToken(sessionStorage.getItem('jwtToken'));
    setNickname(sessionStorage.getItem('nickname'));
  };

  useEffect(() => {
    window.addEventListener('storage', refreshJwtToken);

    return () => {
      window.removeEventListener('storage', refreshJwtToken);
    };
  }, []);

  useEffect(() => {
    if (!jwtToken) {
      navigation('/');
    }
  }, [jwtToken]);

  return (
    <div className="w-full h-[661px] flex">
      <div className="w-[160px] h-full mr-[125px] flex flex-col">
        <div className="border-l-4 border-[#fb6c05]">
          <p className="text-right text-[30px] font-semibold tracking-tighter">
            마이페이지
          </p>
        </div>
        <div className="mt-3">
          <div
            onClick={() => onIndexClick(0)}
            className={cls(
              curIndex === 0 ? 'bg-[#0a2996] border-[#3b7ab2] text-white' : '',
              'cursor-pointer h-11 flex justify-center items-center border-[1px] border-[#cdcdd1] border-opacity-20'
            )}
          >
            내 정보 변경
          </div>
          <div
            onClick={() => onIndexClick(1)}
            className={cls(
              curIndex === 1 ? 'bg-[#0a2996] border-[#3b7ab2] text-white' : '',
              'cursor-pointer h-11 flex justify-center items-center border-[1px] border-[#cdcdd1] border-t-0  border-opacity-20'
            )}
          >
            비밀번호 변경
          </div>
          <div
            onClick={() => onIndexClick(2)}
            className={cls(
              curIndex === 2 ? 'bg-[#0a2996] border-[#3b7ab2] text-white' : '',
              'cursor-pointer h-11 flex justify-center items-center border-[1px] border-[#cdcdd1] border-t-0  border-opacity-20'
            )}
          >
            탈퇴하기
          </div>
        </div>
      </div>

      <div className="w-[900px]">
        <p className="text-[#182f43] text-[41px] font-semibold">
          {curIndex === 0
            ? '내 정보 변경'
            : curIndex === 1
            ? '비밀번호 변경'
            : '탈퇴하기'}
        </p>
        <div className="mt-6">
          {curIndex === 0 ? (
            <ChangeInfo onBackClick={onBackClick} nickname={nickname} />
          ) : curIndex === 1 ? (
            <ChangePassword onBackClick={onBackClick} />
          ) : (
            <Exit onBackClick={onBackClick} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Mypage;
