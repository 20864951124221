import { useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { useLocation, useNavigate } from 'react-router-dom';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useQuery } from 'react-query';
import { wikiApi } from 'libs/api';
import dayjs from 'dayjs';
import { Viewer } from '@toast-ui/react-editor';

function WikiCompare() {
  const navigation = useNavigate();
  const location = useLocation();
  const title = decodeURI(location?.search?.split('?')[1].split('&')[0].split('=')[1]);
  const version = decodeURI(location?.search?.split('?')[1].split('&')[1].split('=')[1]);
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem('jwtToken'));
  const { data: historyData, isLoading: historyLoading } = useQuery(
    ['history', title, version, jwtToken],
    wikiApi.wikiHistory
  );
  const { data: recentAcceptedData, isLoading: AcceptedLoading } = useQuery(
    ['recentAccepted', title, version, jwtToken],
    wikiApi.wikiHistoryRecentAccepted
  );
  const isLoading = historyLoading || AcceptedLoading;
  console.log(historyData);

  const onBeforeClick = () => {
    navigation(-1);
  };

  return isLoading ? null : (
    <div className="w-[1306px] flex flex-col">
      <div className="w-full h-[105px] flex justify-between ">
        <div className="flex flex-row items-center">
          <div className="h-full tracking-tighter pl-[20px] border-l-[6px] border-[#0F43F9] w-[830px] text-[35px] text-[#182F43] font-semibold">
            <span>{title}</span>
          </div>
        </div>
        <div className="w-[424px] h-[130px]">
          <div className="flex flex-col ml-2 mt-2">
            <p className="flex">
              <span className="font-semibold w-[100px] mr-8">최종 편집자</span>
              <span className="mr-5">{historyData.data.nickname}</span>
              <span className="text-[#3F69FA]">
                {historyData.data.authority === 'ADMIN'
                  ? '관리자'
                  : historyData.data.authority === 'DOCTOR'
                  ? '한의사'
                  : historyData.data.authority === 'STUDENT'
                  ? '한의대생'
                  : '일반회원'}
              </span>
            </p>
            <p>
              <span className="font-semibold w-[100px] mr-8">최종 편집일시</span>
              <span>{dayjs(historyData.data.createdAt).format('YYYY-MM-DD')}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-around mt-[56px] font-[600] fontcolor mb-4">
        <span>이전 버전</span>
        <span>당신의 편집</span>
      </div>
      <div className="w-full max-h-[331px] overflow-y-auto overflow-x-hidden">
        <ReactDiffViewer
          showDiffOnly={false}
          oldValue={recentAcceptedData.data.content || ''}
          newValue={historyData.data.content}
          splitView={true}
        />
      </div>

      <div className="pt-[70px] " data-color-mode="light">
        <Viewer initialValue={historyData.data.content} />
      </div>
      <div className="w-full flex items-center justify-center mt-[112px] mb-[400px]">
        <span
          onClick={onBeforeClick}
          className="cursor-pointer w-[157px] h-[54px] rounded-lg bg-[#3F69FA] text-[18px] text-white flex justify-center items-center"
        >
          이전으로
        </span>
      </div>
    </div>
  );
}

export default WikiCompare;
