import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Fab, Footer, Header, Page404, SideComponent } from 'components';
import {
  Acupoints,
  Admin,
  FindAccount,
  Home,
  Login,
  Mypage,
  Prescription,
  Privacy,
  ResetAccount,
  SearchResult,
  Library,
  Terms,
  Wiki,
  WikiCompare,
  WikiDetail,
  WikiEdit,
  WikiHistory,
  LibraryEdit,
  LibraryDetail,
} from 'pages';
import CreateAccount from 'pages/CreateAccount';
import { cls } from 'libs/utils';
import { isMobile } from 'react-device-detect';
import LibraryEditWyisygs from 'pages/LibraryEditWyisygs';

function RootRouter() {
  const location = useLocation();
  const [headerButtonText, setHeaderButtonText] = useState('');

  useEffect(() => {
    if (location.pathname === '/login') {
      setHeaderButtonText('회원가입');
    } else if (location.pathname === '/createAccount') {
      setHeaderButtonText('로그인');
    } else {
      setHeaderButtonText('로그인');
    }
  }, [location]);

  return (
    <div>
      <Body>
        {location.pathname === '/' ? (
          <Routes>
            <Route path="/" element={<Home />}></Route>
          </Routes>
        ) : (
          <div>
            <Header buttonTitle={headerButtonText} />
            {location.pathname.includes('/acupediaAdmin') ? (
              <Routes>
                <Route path="/acupediaAdmin/:id" element={<Admin />}></Route>
                <Route
                  path="/acupediaAdmin/library/search"
                  element={<Admin />}
                ></Route>
              </Routes>
            ) : (
              <div
                className={cls(
                  'flex flex-col items-center mt-[30px]',
                  isMobile ? 'w-[1440px]' : ''
                )}
              >
                <div className="w-[1285px] flex justify-between">
                  <Routes>
                    <Route path="/login" element={<Login />}></Route>
                    <Route
                      path="/findAccount"
                      element={<FindAccount />}
                    ></Route>
                    <Route
                      path="/resetAccount"
                      element={<ResetAccount />}
                    ></Route>
                    <Route
                      path="/createAccount"
                      element={<CreateAccount />}
                    ></Route>
                    <Route
                      path="/searchResult"
                      element={<SearchResult />}
                    ></Route>
                    <Route path="/wikiDetail" element={<WikiDetail />}></Route>
                    <Route path="/wiki" element={<Wiki />}></Route>
                    <Route path="/acupoints" element={<Acupoints />}></Route>
                    <Route path="/library" element={<Library />}></Route>
                    <Route
                      path="/prescription"
                      element={<Prescription />}
                    ></Route>
                    <Route path="/wikiEdit" element={<WikiEdit />}></Route>
                    <Route
                      path="/wikiHistory"
                      element={<WikiHistory />}
                    ></Route>
                    <Route
                      path="/WikiCompare"
                      element={<WikiCompare />}
                    ></Route>
                    <Route
                      path="/libraryEdit"
                      element={<LibraryEdit />}
                    ></Route>
                    <Route
                      path="/libraryEditWyisygs"
                      element={<LibraryEditWyisygs />}
                    ></Route>
                    <Route
                      path="/libraryDetail"
                      element={<LibraryDetail />}
                    ></Route>
                    <Route path="/mypage" element={<Mypage />}></Route>
                    <Route path="/terms" element={<Terms />}></Route>
                    <Route path="/privacy" element={<Privacy />}></Route>
                    <Route path="*" element={<Page404 />} />
                  </Routes>
                  {location.pathname !== '/login' &&
                  location.pathname !== '/createAccount' &&
                  location.pathname !== '/findAccount' &&
                  location.pathname !== '/resetAccount' &&
                  location.pathname !== '/mypage' &&
                  location.pathname !== '/terms' &&
                  location.pathname !== '/privacy' &&
                  location.pathname !== '/wikiEdit' &&
                  location.pathname !== '/wikiCompare' &&
                  location.pathname !== '/libraryEdit' &&
                  location.pathname !== '/libraryEditWyisygs' &&
                  location.pathname !== '/acupediaAdmin' ? (
                    // && location.key !== 'default'
                    <SideComponent />
                  ) : null}
                </div>
              </div>
            )}
          </div>
        )}
      </Body>
      <div className="relative">
        {location.pathname.includes('/libraryDetail') ||
        location.pathname.includes('/searchResult') ||
        location.pathname.includes('/wiki') ||
        location.pathname.includes('/acupoints') ||
        location.pathname.includes('/prescription') ? (
          <Fab />
        ) : null}
      </div>
      <Footer />
    </div>
  );
}

export default RootRouter;

const Body = styled.div`
  /* width: 1440px;
  margin: 0 auto; */
  min-width: 1440px;
  min-height: ${isMobile ? 'calc(100vh + 398px)' : 'calc(100vh - 322px)'};
  /* margin-bottom: 50px; */
  /* min-height: calc(100vh - 322px); */

  /* scale: 0.75; */
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-x: auto;
`;
