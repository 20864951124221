import React from 'react';

const HighlightText = ({ text, highlight }: any) => {
  const content = text?.split(highlight);
  return content?.map((normal: string, i: number) => (
    <React.Fragment key={i}>
      {i > 0 ? (
        <>
          <span className="bg-yellow-100 text-[17px]">{highlight}</span>
          <span className="text-[17px]">{normal}</span>
        </>
      ) : (
        <span>{normal}</span>
      )}
    </React.Fragment>
  ));
};

export default HighlightText;
