const BASE_URL = 'https://zmffkdnemgus.xyz';

// export const matchingApi = {
//   resultMatch: ({ queryKey }: any) => {
//     const [_, email, token, applyType] = queryKey;

//     return fetch(
//       `${BASE_URL}/result/match?id=${email}&passCode=${token}&applyType=${applyType}`
//     ).then((res) => res.json());
//   },
//   resultPersonal: ({ queryKey }: any) => {
//     const [_, email] = queryKey;
//     return fetch(`${BASE_URL}/result/personal?email=${email}`).then((res) => res.json());
//   }
//   curApply: () => fetch(`${BASE_URL}/apply`).then((res) => res.json())
// };

export const searchApi = {
  searchWord: ({ queryKey }: any) => {
    const [_, word] = queryKey;
    return fetch(`${BASE_URL}/search?word=${word}`).then((res) => res.json());
  },
  searchWiki: ({ queryKey }: any) => {
    const [_, word, offset] = queryKey;
    console.log(offset);

    return fetch(
      `${BASE_URL}/search/wiki?word=${word}&limit=20&offset=${offset}`
    ).then((res) => res.json());
  },

  searchAcupoint: ({ queryKey }: any) => {
    const [_, word, offset] = queryKey;
    return fetch(
      `${BASE_URL}/search/acupoint?word=${word}&limit=5&offset=${offset}`
    ).then((res) => res.json());
  },

  searchPrescription: ({ queryKey }: any) => {
    const [_, word, offset] = queryKey;
    return fetch(
      `${BASE_URL}/search/prescription?word=${word}&limit=20&offset=${offset}`
    ).then((res) => res.json());
  },

  searchLibrary: ({ queryKey }: any) => {
    const [_, word, offset] = queryKey;
    return fetch(
      `${BASE_URL}/search/library?word=${word}&limit=20&offset=${offset}`
    ).then((res) => res.json());
  },
};

export const wikiApi = {
  wikiDocument: ({ queryKey }: any) => {
    const [_, title] = queryKey;
    return fetch(`${BASE_URL}/wiki/document?title=${title}`).then((res) =>
      res.json()
    );
  },

  libraryDocument: ({ queryKey }: any) => {
    const [_, title] = queryKey;
    return fetch(`${BASE_URL}/library/document?title=${title}`).then((res) =>
      res.json()
    );
  },

  wikiComment: ({ queryKey }: any) => {
    const [_, wikiTitle, jwtToken] = queryKey;
    return fetch(`${BASE_URL}/comment/all?wikiTitle=${wikiTitle}`, {
      headers: {
        Authorization: jwtToken ? `Bearer ${jwtToken}` : '',
      },
    }).then((res) => res.json());
  },

  wikiRecentChanges: () =>
    fetch(`${BASE_URL}/wiki/recentChanges`).then((res) => res.json()),

  wikiHistory: ({ queryKey }: any) => {
    const [_, title, version, jwtToken] = queryKey;
    return fetch(`${BASE_URL}/history?title=${title}&version=${version}`, {
      headers: {
        Authorization: jwtToken ? `Bearer ${jwtToken}` : '',
      },
    }).then((res) => res.json());
  },
  wikiHistoryRecentAccepted: ({ queryKey }: any) => {
    const [_, title, version, jwtToken] = queryKey;
    return fetch(
      `${BASE_URL}/history/recentAccepted?title=${title}&version=${version}`,
      {
        headers: {
          Authorization: jwtToken ? `Bearer ${jwtToken}` : '',
        },
      }
    ).then((res) => res.json());
  },
  wikiHistoryAll: ({ queryKey }: any) => {
    const [_, title, offset, jwtToken] = queryKey;
    return fetch(
      `${BASE_URL}/history/all?limit=50&offset=${offset}&title=${title}`,
      {
        headers: {
          Authorization: jwtToken ? `Bearer ${jwtToken}` : '',
        },
      }
    ).then((res) => res.json());
  },
};

export const libraryApi = {
  libraryDocument: ({ queryKey }: any) => {
    const [_, title] = queryKey;
    return fetch(`${BASE_URL}/library/document?title=${title}`).then((res) =>
      res.json()
    );
  },

  libraryComment: ({ queryKey }: any) => {
    const [_, libraryHistoryTitle, jwtToken] = queryKey;
    return fetch(
      `${BASE_URL}/libraryComment/all?libraryTitle=${libraryHistoryTitle}`,
      {
        headers: {
          Authorization: jwtToken ? `Bearer ${jwtToken}` : '',
        },
      }
    ).then((res) => res.json());
  },
};

export const adminAPi = {
  adminPost: ({ queryKey }: any) => {
    const [_, page, jwtToken] = queryKey;
    return fetch(`${BASE_URL}/admin/post?limit=20&page=${page}`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }).then((res) => res.json());
  },
  adminUser: ({ queryKey }: any) => {
    const [_, offset, jwtToken] = queryKey;
    return fetch(`${BASE_URL}/admin/user?limit=20&offset=${offset}`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }).then((res) => res.json());
  },
  adminLib: ({ queryKey }: any) => {
    const [_, page, jwtToken] = queryKey;
    return fetch(`${BASE_URL}/admin/library?limit=20&page=${page}`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }).then((res) => res.json());
  },
  adminPopup: ({ queryKey }: any) => {
    const [_, offset, jwtToken] = queryKey;
    return fetch(`${BASE_URL}/admin/popup?limit=20&offset=${offset}`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }).then((res) => res.json());
  },
};

export const popupAPi = {
  adminPopupEdit: ({ queryKey }: any) => {
    const [_, id, jwtToken] = queryKey;
    return fetch(`${BASE_URL}/popup/${id}`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }).then((res) => res.json());
  },
};

export const userPopupAPi = {
  popupList: ({ queryKey }: any) => {
    const [_, jwtToken] = queryKey;
    if(jwtToken){
      return fetch(`${BASE_URL}/popup/usrPopup`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }).then((res) => res.json());
    }else{
      return fetch(`${BASE_URL}/popup/usrPopup`).then((res) => res.json());
    }
  },
};

export const categoryAPi = {
  categoryList: () =>
    fetch(`${BASE_URL}/category/categories`).then((res) => res.json()),

  libraryIndex: () =>
    fetch(`${BASE_URL}/category/categoryIndex`).then((res) => res.json()),
};
