// import 'github-markdown-css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Comments, WikiRule } from 'components';
import GoogleAdvertise from 'components/GoogleAdvertise';
import { HORIZONTAL } from 'constants/GoogleAds';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { wikiApi } from 'libs/api';
import dayjs from 'dayjs';
// import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/react-editor';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import settingSlice from 'slices/setting';
import styled from 'styled-components';

function WikiDetail() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem('jwtToken'));
  const authority = sessionStorage.getItem('authority');
  const curLocation = location.pathname + location.search;
  const title = decodeURIComponent(
    location?.search?.split('?')[1].split('&')[0].split('=')[1]
  );
  const version = location?.search?.split('?')[1].split('&')[1]
    ? decodeURIComponent(
        location?.search?.split('?')[1].split('&')[1].split('=')[1]
      )
    : '';
  const {
    data,
    refetch: detailRefetch,
    isFetching: detailFetching,
  } = useQuery(['wikiDetail', title], wikiApi.wikiDocument);
  const {
    data: historyData,
    refetch: historyRefetch,
    isFetching: historyFetching,
  } = useQuery(['history', title, version, jwtToken], wikiApi.wikiHistory, {
    enabled: !!version,
  });
  const isLoading = detailFetching || historyFetching;

  const onEditClick = () => {
    if (jwtToken) {
      if (
        authority === 'ADMIN' ||
        authority === 'DOCTOR' ||
        authority === 'STUDENT'
      ) {
        navigation({
          pathname: '/wikiEdit',
          search: `?title=${title}`,
        });
      } else {
        toast(
          '일반회원은 위키 편집 권한이 없습니다. 한의사/한의대생은 등업을 통해 편집권한을 얻으시기 바랍니다.'
        );
      }
    } else {
      dispatch(
        settingSlice.actions.setLastPageBeforeLogin({
          lastPageBeforeLogin: curLocation,
        })
      );
      navigation('/login');
    }
  };

  const onHistoryClick = () => {
    navigation({
      pathname: '/wikiHistory',
      search: `?title=${title}&index=1`,
    });
  };
  const onNewWikiClick = () => {
    if (jwtToken) {
      if (
        authority === 'ADMIN' ||
        authority === 'DOCTOR' ||
        authority === 'STUDENT'
      ) {
        dispatch(
          settingSlice.actions.setLastPageBeforeLogin({
            lastPageBeforeLogin: curLocation,
          })
        );
        navigation(
          {
            pathname: '/wikiEdit',
            search: `?title=newWiki`,
          },
          {
            state: title,
          }
        );
      } else {
        toast(
          '일반회원은 위키 작성 권한이 없습니다. 한의사/한의대생은 등업을 통해 편집권한을 얻으시기 바랍니다.'
        );
      }
    } else {
      dispatch(
        settingSlice.actions.setLastPageBeforeLogin({
          lastPageBeforeLogin: curLocation,
        })
      );
      navigation(
        {
          pathname: '/login',
          search: '',
        },
        {
          state: null,
        }
      );
    }
  };

  const refreshJwtToken = () => {
    setJwtToken(sessionStorage.getItem('jwtToken'));
  };

  useEffect(() => {
    window.addEventListener('storage', refreshJwtToken);

    return () => {
      window.removeEventListener('storage', refreshJwtToken);
    };
  }, []);

  return (
    <div className="w-[944px] min-h-[2000px]">
      {isLoading ? null : (
        <>
          <div className="h-[137px] flex justify-between">
            <div className="w-[510px] h-fit border-l-4 border-[#0F43F9] pl-4 ">
              <span className="tracking-[-2px] text-[25px] text-[#182F43] font-semibold">
                {title.replace(/ /g, '\u00a0')}
              </span>
            </div>
            {data?.status === 'BAD_REQUEST' ? null : (
              <div className="w-[424px] h-[130px]">
                {version ? null : (
                  <div className="flex justify-between h-[44px]">
                    <span className="rounded-l-lg bg-[#3F69FA] text-white flex-1 flex justify-center items-center border-[#84AED3] border-solid border-2">
                      읽기
                    </span>
                    <span
                      onClick={onEditClick}
                      className="text-[#84AED3] cursor-pointer flex-1 flex justify-center items-center border-[#84AED3] border-solid border-2"
                    >
                      편집
                    </span>
                    <span
                      onClick={onHistoryClick}
                      className="rounded-r-lg text-[#84AED3] cursor-pointer flex-1 flex justify-center items-center border-[#84AED3] border-solid border-2"
                    >
                      히스토리
                    </span>
                  </div>
                )}
                {isLoading ? null : (
                  <div className="flex flex-col h-[86px] justify-evenly ml-2">
                    <p className="flex">
                      <span className="font-semibold w-[100px] mr-8 text-[14px]">
                        최종 편집자
                      </span>
                      <span className="mr-5 text-[14px]">
                        {historyData?.data?.nickname
                          ? historyData.data.nickname
                          : data.data.nickname}
                      </span>
                      <span className="text-[#3F69FA] text-[14px]">
                        {historyData?.data?.authority
                          ? historyData.data.authority === 'ADMIN'
                            ? '관리자'
                            : historyData.data.authority === 'DOCTOR'
                            ? '한의사'
                            : historyData.data.authority === 'STUDENT'
                            ? '한의대생'
                            : '일반회원'
                          : data.data.authority === 'ADMIN'
                          ? '관리자'
                          : data.data.authority === 'DOCTOR'
                          ? '한의사'
                          : data.data.authority === 'STUDENT'
                          ? '한의대생'
                          : '일반회원'}
                      </span>
                    </p>
                    <p>
                      <span className="font-semibold w-[100px] mr-[46px] text-[14px]">
                        최종 편집일시
                      </span>
                      <span className="text-[14px]">
                        {historyData?.data?.createdAt
                          ? dayjs(historyData.data.createdAt).format(
                              'YYYY-MM-DD HH:mm:ss'
                            )
                          : dayjs(data.data.lastModifiedAt).format(
                              'YYYY-MM-DD HH:mm:ss'
                            )}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
          {data?.status === 'BAD_REQUEST' ? (
            <div className="">
              <span className="text-[#9aa6b0] text-[22px] font-light mr-[100px]">
                해당 항목으로 생성된 위키 항목이 없습니다.
              </span>
              <span
                onClick={onNewWikiClick}
                className="cursor-pointer text-[#188aef] text-[22px] font-medium"
              >{`[새 위키 작성하기]`}</span>
            </div>
          ) : null}
          {/* {data?.status === 'BAD_REQUEST' ? null : (
            <div className="mt-[55px] h-[100px]">
              <GoogleAdvertise slot={HORIZONTAL} width={944} height={100} />
            </div>
          )} */}
          {isLoading ? null : (
            <Div>
              <div className="pt-[30px] " data-color-mode="light">
                <Viewer
                  initialValue={
                    historyData?.data?.content
                      ? historyData.data.content
                      : data.data.content
                  }
                />
              </div>
            </Div>
          )}
          {data?.status === 'BAD_REQUEST' ? null : (
            <>
              {/* <div className="mt-[30px] mb-[30px] h-[100px]">
                <GoogleAdvertise slot={HORIZONTAL} width={944} height={100} />
              </div> */}
              {version ? null : <Comments title={title} />}
              <div className="mt-[118px] mb-[78px] w-full">
                <WikiRule />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default WikiDetail;

const Div = styled.div`
  @media print {
    display: flex;
    width: 100%;
    height: 100%;
  }
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;

  overflow: hidden;
`;
