import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  //   black: {
  //     veryDark: '#141414',
  //     darker: '#181818',
  //     lighter: '#2F2F2F'
  //   },
  main: '#0A2996',
  footer: '#EDF6FE',
  fontColor: '#182F43'
};
