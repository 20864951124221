import { MetaTagProvider, ScrollToTop } from 'components';
import RootRouter from 'navigation/RootRouter';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import './styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
      <MetaTagProvider />
      <ScrollToTop />
      <RootRouter />
    </Router>
  );
}

export default App;
