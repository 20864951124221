import { BellIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SearchPrescription() {
  const navigation = useNavigate();
  const [isSearch, setIsSearch] = useState(false);
  const [text, setText] = useState('');

  const onSearchClick = () => {
    setIsSearch(true);
  };
  const onSearchMoveClick = () => {
    if (text.length > 0) {
      navigation({
        pathname: '/prescription',
        search: `?search=${text}&index=1`,
      });
    }
  };
  const onCancelClick = () => {
    setIsSearch(false);
  };

  return (
    <>
      {isSearch ? (
        <div className="relative h-[180px]">
          <div className="bg-white absolute right-0 w-[520px] h-[180px]  shadow-xl flex flex-col items-center justify-between">
            <div className="flex flex-row w-full pt-6 pl-6">
              <div className="w-12 h-12 rounded-full bg-[#E6F4FB] flex items-center justify-center">
                <BellIcon className="h-7 text-[#058DD9]" />
              </div>
              <div className="ml-3">
                <p className="text-lg font-medium">
                고전침구처방 검색하기
                </p>
                <input
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="침구처방 검색하기..."
                  className="outline-none indent-4 rounded mt-2 w-[412px] h-[52px] border-[#CBD5E1] border-2"
                />
              </div>
            </div>

            {/* 버튼영역 */}
            <div className="w-full h-[58px] bg-[#F8FAFC] flex items-center justify-end space-x-[12px] pr-4">
              <div
                onClick={onSearchMoveClick}
                className="cursor-pointer rounded-[5px] mt-2 w-[80px] h-[34px] flex justify-center items-center text-white bg-[#058DD9] text-[15px]"
              >
                검색하기
              </div>
              <div
                onClick={onCancelClick}
                className="cursor-pointer rounded-[5px] mt-2 w-[80px] h-[34px] flex justify-center items-center text-[#475569] bg-white text-[15px] border-2 border-[#CBD5E1]"
              >
                취소하기
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-[180px]  shadow-xl flex flex-col items-center justify-between">
          <div className="mt-6 w-12 h-12 rounded-full bg-[#E6F4FB] flex items-center justify-center">
            <BellIcon className="h-7 text-[#058DD9]" />
          </div>
          <div className="w-full h-[92px] bg-[#F8FAFC] flex flex-col items-center">
            <p className="mt-3 text-[15px] font-medium">
              고전침구처방 검색하기
            </p>
            <div
              onClick={onSearchClick}
              className="cursor-pointer rounded-[5px] mt-2 w-[80px] h-[34px] flex justify-center items-center text-white bg-[#058DD9] text-[15px]"
            >
              검색하기
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SearchPrescription;
