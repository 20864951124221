import HighlightLibrary from 'components/HighlightLibrary';
import HighlightTextTitle from 'components/HighlightTextTitle';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  item: any;
  highlight?: string;
}

function LibraryItem({ item, highlight = '' }: Props) {
  const location = useLocation();
  const navigation = useNavigate();
  const word = decodeURIComponent(
    location?.search?.split('?')[1].split('&')[0].split('=')[1]
  );
  const content = item.content?.replace('\n', '');
  const onLibraryClick = () => {
    navigation({
      pathname: '/libraryDetail',
      search: `?title=${item.libraryTitle}`,
    });
  };
  /*
<div className="space-y-[20px] mt-6 pl-[20px] ">
        {location.pathname !== '/library' ? null : (
          <HighlightLibrary text={content} highlight={highlight} />
        )}
      </div>
*/
  return (
    <div className="flex flex-col">
      <div>
        <div className="text-[15px] text-[#1D80D6] font-[600] w-[500px]  pr-4">
          <div onClick={onLibraryClick} className="cursor-pointer flex">
            {location.pathname !== '/library' ? (
              <span className="text-[18px]">{'·  '}</span>
            ) : null}
            <div className=" ml-[15px]">
              <span className=" border-b-[3px] border-[#1D80D6] text-[15px] ">
                <HighlightTextTitle
                  text={item.libraryTitle.replace(/ /g, '\u00a0')}
                  highlight={highlight || ''}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="space-y-[20px] mt-6 pl-[20px] ">
          <HighlightLibrary text={content} highlight={highlight} />
        </div>
      </div>
    </div>
  );
}

export default LibraryItem;
