import dayjs from 'dayjs';
import { cls } from 'libs/utils';
import { useNavigate } from 'react-router-dom';

interface Props {
  index: number;
  curLocation: string;
  curIndex: number;
  title: string;
  item: any;
}

function HistoryItem({ index, curLocation, curIndex, title, item }: Props) {
  const navigation = useNavigate();

  const onLookClick = () => {
    navigation({
      pathname: '/wikiDetail',
      search: `?title=${title}&version=${item.version}`
    });
  };
  const onCompareClick = () => {
    navigation({
      pathname: '/wikiCompare',
      search: `?title=${title}&version=${item.version}`
    });
  };

  return (
    <div className="flex h-[23px] text-[15px]">
      <div className="w-[60px] mr-[10px] flex justify-center items-center font-semibold">
        {index + 1 + (curIndex - 1) * 20}
      </div>
      <div className="w-[169px] mr-[10px] flex justify-center items-center">
        {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
      </div>
      <div
        onClick={onLookClick}
        className="w-[48px] mr-[10px] flex justify-center items-center text-[#188AEF] font-semibold underline cursor-pointer"
      >
        보기
      </div>
      <div
        onClick={onCompareClick}
        className="w-[48px] mr-[10px] flex justify-center items-center text-[#188AEF] font-semibold underline cursor-pointer"
      >
        비교
      </div>
      <div
        className={cls(
          item.status === 'ACCEPT' ? 'text-[#47CE18]' : 'text-[#EB3428]',
          'w-[48px] mr-[10px] flex justify-center items-center'
        )}
      >
        {item.status === 'ACCEPT' ? '승인' : '거절'}
      </div>
      <div
        className={cls(
          item.status === 'ACCEPT' ? '' : 'text-[#EB3428]',
          'w-[184px] mr-[10px] flex justify-center items-center'
        )}
      >
        {item.status === 'ACCEPT' ? '-' : item.reason}
      </div>
      <div className="w-[184px] mr-[10px] flex justify-center items-center">
        {item.nickname}
      </div>
      <div
        className={cls(
          item.authority === 'NONE' ? 'text-[#60B71C]' : 'text-[#188AEF]',
          'w-[107px] mr-[10px] flex justify-center items-center font-medium'
        )}
      >
        {item.authority === 'ADMIN'
          ? '관리자'
          : item.authority === 'DOCTOR'
          ? '한의사'
          : item.authority === 'STUDENT'
          ? '한의대생'
          : '일반회원'}
      </div>
    </div>
  );
}

export default HistoryItem;
