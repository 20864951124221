export function cls(...classnames: string[]) {
  return classnames.join(' ');
}

export function isToday(curDate: string) {
  const date = new Date(curDate);
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}
