import TUI from '../components/TUI';
import { useEffect, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { useLocation, useNavigate } from 'react-router-dom';
import { WikiRule } from 'components';
import emailjs from '@emailjs/browser';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { md } from 'components/md';
import { useQuery } from 'react-query';
import { wikiApi } from 'libs/api';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import axiosInstance from 'components/AxiosInstance';
import { ClipLoader } from 'react-spinners';
import dayjs from 'dayjs';
import { useAppDispatch } from 'store';
import settingSlice from 'slices/setting';

function WikiEdit() {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const [newString, setNewString] = useState('');
  const title = decodeURI(
    location?.search?.split('?')[1].split('&')[0].split('=')[1]
  );
  const curSearch =
    location.search.split('?')[location.search.split('?').length - 1];
  const curLocation = location.pathname + location.search;
  const [curTitle, setCurTitle] = useState('');
  const authority = sessionStorage.getItem('authority');
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem('jwtToken'));
  const [isLoading, setIsLoading] = useState(false);
  const locationState = location.state as String;
  const { data, isLoading: wikiLoading } = useQuery(
    ['wikiDetail', title],
    wikiApi.wikiDocument,
    {
      enabled: title !== 'newWiki',
      onSuccess: (data) => setCurTitle(data?.data?.title),
    }
  );
  const content = data?.data?.content?.replace(/\\n/g, '\n') || '';
  // console.log(location.state);

  const onReadClick = () => {
    navigation({
      pathname: '/wikiDetail',
      search: `?title=${title}`,
    });
  };
  const onHistoryClick = () => {
    navigation({
      pathname: '/wikiHistory',
      search: `?title=${title}&index=1`,
    });
  };

  const templateParams = {
    title: `${curTitle}`,
  };

  const onSubmitClick = async () => {
    // 새 위키 작성
    if (curTitle === 'newWiki') {
      if (isMobile) {
        alert('해당 제목으로 만들 수 없습니다.');
        return;
      } else {
        toast('해당 제목으로 만들 수 없습니다.');
        return;
      }
    }
    if (
      curTitle.length === 0 ||
      (newString.length === 0 && content.length === 0)
    ) {
      if (isMobile) {
        alert('제목 또는 내용을 입력해주세요.');
        return;
      } else {
        toast('제목 또는 내용을 입력해주세요.');
        return;
      }
    }
    const time = new Date();
    setIsLoading(true);
    const response = await axiosInstance
      .post(
        `/history/submit`,
        {
          content: newString.length === 0 ? content : newString,
          createdAt: time.toISOString(),
          title: curTitle,
          previousTitle: curTitle !== title ? title : null,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .finally(() => setIsLoading(false));
    console.log(response);
    if (response.status === 200) {
      const email = emailjs
        .send(
          'service_gsvg4rq',
          'template_53hmqzm',
          templateParams,
          'Mv73VHQYTX_Jk7yrQ'
        )
        .then(
          function (response) {
            console.log('SUCCESS!', response.status, response.text);
          },
          function (error) {
            console.log('FAILED...', error);
          }
        );
      console.log(email);

      if (isMobile) {
        alert('저장되었습니다. ');
      } else {
        toast('저장되었습니다. ');
      }
      if (title === 'newWiki') {
        navigation('/');
      } else {
        navigation({
          pathname: '/wikiDetail',
          search: `?title=${curTitle}`,
        });
      }
    }
  };

  const onCancelClick = () => {
    navigation(-1);
  };

  const checkEnter = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const refreshJwtToken = () => {
    setJwtToken(sessionStorage.getItem('jwtToken'));
  };

  useEffect(() => {
    if (title === 'newWiki') {
      setCurTitle(String(locationState));
    }
    window.addEventListener('storage', refreshJwtToken);

    return () => {
      window.removeEventListener('storage', refreshJwtToken);
    };
  }, []);

  useEffect(() => {
    if (!jwtToken) {
      dispatch(
        settingSlice.actions.setLastPageBeforeLogin({
          lastPageBeforeLogin: curLocation,
        })
      );
      navigation('/login');
    }
  }, [jwtToken]);

  // useEffect(() => {
  //   if (data?.status === 'BAD_REQUEST') {
  //     navigation('/404');
  //   }
  // }, [data]);

  if (data?.status === 'BAD_REQUEST') {
    return null;
  }

  return wikiLoading ? null : (
    <div className="w-[1306px] min-h-[1500px] flex flex-col">
      {curSearch !== 'admin' ? (
        <span className="text-[30px] mb-3">편집하기</span>
      ) : null}
      <div className="w-full flex justify-between ">
        <div className="flex flex-row">
          <div className="tracking-tighter pl-[20px] border-l-[6px] border-[#0F43F9] w-[830px] text-[35px] text-[#182F43] font-semibold">
            {title === 'newWiki' ? (
              // <textarea
              //   maxLength={40}
              //   className="w-full resize-none pr-16 tracking-tighter font-semibold"
              //   placeholder="제목을 입력하세요."
              //   onChange={(e) => setCurTitle(e.target.value)}
              //   value={curTitle}
              //   onKeyPress={checkEnter}
              //   autoFocus
              // />
              <span>{location.state as String}</span>
            ) : (
              <>
                {authority === 'ADMIN' ? (
                  <textarea
                    maxLength={40}
                    className="w-full resize-none tracking-tighter font-semibold"
                    placeholder="제목을 입력하세요."
                    value={curTitle}
                    onChange={(e) => setCurTitle(e.target.value)}
                    onKeyPress={checkEnter}
                    autoFocus
                  />
                ) : (
                  <span>{title}</span>
                )}
              </>
            )}
          </div>
        </div>
        <div className="w-[424px]">
          {title !== 'newWiki' ? (
            <div className="flex justify-between h-[44px] ">
              <span
                onClick={onReadClick}
                className="rounded-l-lg text-[#84AED3] cursor-pointer flex-1 flex justify-center items-center border-[#84AED3] border-solid border-2"
              >
                읽기
              </span>
              <span className="bg-[#3F69FA] text-white flex-1 flex justify-center items-center border-[#84AED3] border-solid border-2">
                편집
              </span>
              <span
                onClick={onHistoryClick}
                className="rounded-r-lg text-[#84AED3] cursor-pointer flex-1 flex justify-center items-center border-[#84AED3] border-solid border-2"
              >
                히스토리
              </span>
            </div>
          ) : null}
          {title !== 'newWiki' ? (
            <div className="flex flex-col ml-2 mt-2">
              <p className="flex">
                <span className="font-semibold w-[100px] mr-8">
                  최종 편집자
                </span>
                <span className="mr-5">{data?.data?.nickname}</span>
                <span className="text-[#3F69FA]">
                  {data?.data?.authority === 'ADMIN'
                    ? '어드민'
                    : data?.data?.authority === 'DOCTOR'
                    ? '한의사'
                    : data?.data?.authority === 'STUDENT'
                    ? '한의대생'
                    : '일반회원'}
                </span>
              </p>
              <p>
                <span className="font-semibold w-[100px] mr-8">
                  최종 편집일시
                </span>
                <span>
                  {data?.data?.lastModifiedAt
                    ? dayjs(data?.data?.lastModifiedAt).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )
                    : ''}
                </span>
              </p>
            </div>
          ) : null}
        </div>
      </div>
      {title !== 'newWiki' ? (
        <>
          <div className="w-full flex justify-around mt-[56px] font-[600] fontcolor mb-4">
            <span>최신판</span>
            <span>당신의 편집</span>
          </div>
          <div className="w-full max-h-[331px] overflow-y-auto overflow-x-hidden">
            <ReactDiffViewer
              showDiffOnly={false}
              oldValue={content}
              newValue={newString}
              splitView={true}
            />
          </div>
        </>
      ) : null}

      <div className="w-full mt-[80px]">
        <div className="flex flex-row-reverse pr-4 mb-[15px]">
          <button
            onClick={() =>
              window.open(
                'https://gist.github.com/ihoneymon/652be052a0727ad59601'
              )
            }
            className="self-end w-[250px] bg-[#0A2996] hover:bg-blue-700 text-white text-[14px] font-bold py-2 px-4 ml-4 rounded"
          >
            아큐피디아 편집기 사용설명서
          </button>
        </div>
        <TUI oldString={content} setNewString={setNewString} />
      </div>
      <div className="w-full flex items-center justify-end mt-[58px]">
        <span
          onClick={onSubmitClick}
          className="cursor-pointer w-[127px] h-[54px] rounded-lg bg-[#3F69FA] text-[18px] text-white flex justify-center items-center"
        >
          {isLoading ? <ClipLoader size={20} color="white" /> : '완료'}
        </span>
        <span
          onClick={onCancelClick}
          className="cursor-pointer text-[18px] text-[#FF0000] ml-[45px] border-b-2 border-[#FF0000]"
        >
          취소
        </span>
      </div>
      <div className="mt-[118px] mb-[78px] w-full">
        <WikiRule />
      </div>
    </div>
  );
}

export default WikiEdit;
