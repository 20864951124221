const privacy = `

</br>

<p>
아큐피디아는 개인정보보호법에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같이 개인정보 처리방침을 수립·공개합니다.</p>
</br>
<p>1. 수집하는 개인정보 항목 및 수집 방법<br>
아큐피디아는 서비스 제공을 위한 아래와 같은 필요 최소한의 개인정보를 수집하고 있습니다.</p>
</br>
<p>1) 홈페이지 회원 가입 및 관리<br>
필수항목: 이메일, 비밀번호, 이름(닉네임)<br>
선택항목: 멤버십 회원 인증을 위한 한의사 면허증, 한의대 학생증, 기타 본인 신분을 증명할 수 있는 서류</p>
</br>
<p>2) 서비스 이용과정에서 아래와 같은 개인정보들이 자동으로 생성되어 수집될 수 있습니다.<br>
- 서비스 이용기록, 접속로그, 쿠키, IP 주소</p>
</br>
<p>아큐피디아는 다음과 같은 방법으로 개인정보를 수집하고 있습니다.<br>
1) 이용자로부터의 제공<br>
2) 이용자의 인터넷 이용 시 자동으로 생성되는 정보의 수집</p>
</br>
<p>2. 개인정보의 수집 및 이용목적<br>
아큐피디아는 다음과 같은 목적을 위하여 개인정보를 수집하며, 해당 목적 내에서만 수집한 개인정보를 이용하고 있습니다.<br>
차후 이용목적이 변경될 시에는 사전에 동의를 구합니다.</p>
</br>
<p>1) 아이디, 비밀번호, 이름(닉네임) : 서비스 이용에 따른 본인식별, 중복가입 확인, 부정이용 방지를 위해 사용됩니다.<br>
2) 이메일 : 전체메일발송, 패스워드 분실시 필요한 정보제공 및 민원처리 등을 위해 사용됩니다.<br>
3) 서비스 이용기록, 접속로그, 쿠키, IP 주소 : 불량회원의 부정 이용방지와 비인가 사용방지, 통계학적 분석에 사용됩니다.<br>
4) 그 외 선택항목 : 개인 맞춤 서비스(멤버십)를 제공하기 위해 사용됩니다.</p>
</br>
<p>3. 개인정보의 보유 및 이용기간<br>
아큐피디아는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용합니다.<br>
단, 다음의 정보에 대해서는 회원탈퇴 후 아래의 이유로 명시한 기간 동안 보존합니다.</p>
</br>
<p>·보존항목 : 이메일<br>
·보존근거 : 빈번한 가입과 탈퇴를 반복하는 악의적 이용 방지, 서비스 이용의 혼선 방지<br>
·보존기간 : 30일</p>
</br>
<p>·보존항목 : 이름(닉네임)<br>
·보존근거 : 닉네임을 기준으로 설계되어 있어 탈퇴 후 타인이 동일한 닉네임으로 가입할 경우 서비스 이용의 혼선 방지<br>
·보존기간 : 서비스 제공 종료시까지</p>
</br>
<p>
4. 개인정보의 파기절차 및 방법<br>
아큐피디아는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.</p>
</br>
<p>1) 파기절차<br>
회원이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.</p>
</br>
<p>일정기간 저장된 개인정보는 법령에 의한 경우를 제외하고는 보유 목적 이외의 다른 목적으로 이용되지 않습니다.</p>
</br>
<p>2) 파기방법<br>
전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
</br>
<p>5. 개인정보 제공<br>
아큐피디아는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
</br>
<p>1) 이용자들이 사전에 동의한 경우<br>
2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br>
3) 통계작성, 학술연구를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우</p>
</br>
<p>6 수집한 개인정보의 위탁<br>
아큐피디아는 회원의 동의없이 회원 정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 회원에게 통지하고 필요한 경우 사전 동의를 구합니다.</p>
</br>
<p>7. 이용자 및 법정대리인의 권리와 그 행사방법</p>
</br>
<p>이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다. 이용자의 개인정보 조회/수정은 'mypage'(또는 '회원정보수정' 등)에서 할 수 있으며, 가입해지(동의철회)는 개인정보관리책임자에게 이메일로 연락하시면 지체 없이 조치하겠습니다.</p>
</br>
<p>아큐피디아는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보를 ‘개인정보 파기절차 및 방법’ 에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 하겠습니다.</p>
</br>
<p>
8. 개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항</p>
</br>
<p>아큐피디아는 이용자에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 등을 운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장되기도 합니다. 아큐피디아는 다음과 같은 목적을 위해 쿠키를 사용합니다.</p>
</br>
<p>1) 쿠키 등 사용 목적</p>
</br>
<p>회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 발자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스를 제공하는데 쓰이며, 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.</p>
</br>
<p>2) 쿠키 설정 거부 방법</p>
</br>
<p>이용자는 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나, 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.</p>
</br>
<p>9. 개인정보의 기술적/관리적 보호 대책<br>
이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 아큐피디아는 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.</p>
</br>
<p>• 비밀번호 암호화<br>
회원 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.</p>
</br>
<p>• 해킹 등에 대비한 대책<br>
해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.</p>
</br>
<p>10. 개인정보 관리 책임자<br>
- 개인정보 보호업무 담당자: 아큐피디아 운영팀 김태민<br>
- 주소 : 경기도 용인시 기흥구 동백죽전대로527번길 98-2, 302호<br>
- 이메일 : admin@acupedia.net</p>
</br>
<p>11. 고지의 의무<br>
이 개인정보 취급방침이 법령, 정책 또는 보안기술의 변경에 따라 내용이 추가, 삭제 및 수정될 경우에는 변경사항 시행일 7일전부터 아큐피디아 사이트에 공지됩니다.</p>
`;
export default privacy;
