import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { FiArrowRight } from 'react-icons/fi';
import { isBrowser } from 'react-device-detect';
import axiosInstance from 'components/AxiosInstance';
import GoogleAdvertise from 'components/GoogleAdvertise';
import { HORIZONTAL } from 'constants/GoogleAds';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import { SignUpModal } from 'components';

function Login() {
  const { lastPageBeforeLogin, accessToken } = useAppSelector((state) => state.setting);
  const location = useLocation();
  const navigation = useNavigate();
  const { register, handleSubmit } = useForm();
  const [passwordType, setPasswordType] = useState('password');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onValid = async (validForm: any) => {
    console.log();

    if (loading) return;
    setLoading(true);
    console.log(validForm);

    const response = await axiosInstance
      .post('/authenticate/login', {
        email: validForm.email,
        password: validForm.password
      })
      .finally(() => setLoading(false));
    if (response.status === 200) {
      sessionStorage.setItem('jwtToken', response.data.data.jwtToken);
      sessionStorage.setItem('nickname', response.data.data.nickname);
      sessionStorage.setItem('email', response.data.data.email);
      sessionStorage.setItem('authority', response.data.data.authority);
      if (response.status === 200) {
        console.log(lastPageBeforeLogin);

        if (lastPageBeforeLogin === '/createAccount') {
          navigation('/');
          return;
        }
        if (lastPageBeforeLogin) {
          navigation(lastPageBeforeLogin);
        } else {
          navigation('/');
        }
      }
    }

    console.log(response);
  };

  const successGoogle = async (res: any) => {
    console.log(res);
    const formData = new FormData();
    formData.append('code', res?.code);
    formData.append(
      'client_id',
      '350409008095-sfstjhbcmv7g7te156d5p2c18l13u1eg.apps.googleusercontent.com'
    );
    formData.append('client_secret', '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');
    formData.append(
      'redirect_uri',
      process.env.NODE_ENV == 'production'
        ? 'https://www.acupedia.net'
        : 'http://localhost:3000'
    );
    formData.append('grant_type', 'authorization_code');

    const response = await axiosInstance.post(
      'https://oauth2.googleapis.com/token',
      formData
    );
    console.log(response);

    if (response.status === 200) {
      const response2 = await axiosInstance.get(
        `https://www.googleapis.com/userinfo/v2/me?access_token=${response.data.access_token}`
      );
      if (response2.status === 200) {
        console.log(response2);

        const response3 = await axiosInstance
          .post('/authenticate/google', {
            email: response2.data.email,
            nickname: response2.data.name
          })
          .finally(() => setLoading(false));
        console.log(response3);

        if (response3.status === 200) {
          sessionStorage.setItem('jwtToken', response3.data.data.jwtToken);
          sessionStorage.setItem('nickname', response3.data.data.nickname);
          sessionStorage.setItem('email', response3.data.data.email);
          sessionStorage.setItem('authority', response3.data.data.authority);
          if (response3.status === 200) {
            if (lastPageBeforeLogin === '/createAccount') {
              navigation('/');
              return;
            }
            if (lastPageBeforeLogin) {
              navigation(lastPageBeforeLogin);
            } else {
              navigation('/');
            }
          }
        }
      }
      console.log(response2);
    }
  };

  const failGoogle = (response: any) => {
    console.log(response);
  };

  const createAccount = () => {
    // navigation(`/createAccount`);
    setIsModalOpen(true);
  };
  const clickPasswordIcon = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const login = useGoogleLogin({
    onSuccess: successGoogle,
    flow: 'auth-code'
  });

  return (
    <Container>
      <TitleWrap>
        <LoginTitle>로그인 하기</LoginTitle>
        <SignUpTitle>계정 생성하기</SignUpTitle>
        <SignUpButton onClick={createAccount}>
          <FiArrowRight className="rightArrow" />
        </SignUpButton>
      </TitleWrap>
      <SubTitleWrap>아큐피디아에 오신 것을 환영합니다.</SubTitleWrap>
      <LoginWrap>
        <Form onSubmit={handleSubmit(onValid)}>
          <Input
            {...register('email', { required: true })}
            placeholder="이메일을 입력해주세요."
          />
          <PasswordWrap>
            <Input
              {...register('password', { required: true })}
              type={passwordType}
              placeholder="비밀번호를 입력해주세요."
            ></Input>
            {passwordType === 'password' ? (
              <BsEyeSlash onClick={clickPasswordIcon} className="password" />
            ) : (
              <BsEye onClick={clickPasswordIcon} className="password" />
            )}
          </PasswordWrap>
          <LoginButton>
            {loading ? <ClipLoader size={20} color="white" /> : '로그인 하기'}
          </LoginButton>
        </Form>
      </LoginWrap>
      <SplitLine />
      <GoogleButton onClick={login}>
        <FcGoogle className="google" />
        Continue with Google
      </GoogleButton>
      <ForgetButton>
        <Link to={'/findAccount'}>비밀번호를 잊으셨나요?</Link>
      </ForgetButton>
      <div className="w-full h-[1px] bg-[#dddddd] mt-[50px] mb-[103px] invisible" />
      {/* <GoogleAds>
        <GoogleAdvertise slot={HORIZONTAL} width={790} height={129} />
      </GoogleAds> */}
      <SignUpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </Container>
  );
}

export default Login;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${isBrowser ? '' : '1440px'};
  margin-left: auto;
  margin-right: auto;
  .google {
    margin-right: 15px;
    width: 24px;
    height: 24px;
  }
  .password {
    position: absolute;
    width: 17px;
    height: 17px;
    right: 24px;
    cursor: pointer;
    color: #dadada;
    margin-top: -7px;
  }
  .rightArrow {
    color: white;
    width: 38px;
    height: 38px;
  }
`;
const TitleWrap = styled.div`
  width: 600px;
  height: 70px;
  display: flex;
  flex-direction: row;
`;
const LoginTitle = styled.span`
  font-size: 54px;
  font-weight: 600;
  color: ${(props) => props.theme.fontColor};
`;
const SignUpTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: 13px;
  margin-right: 13px;
  color: ${(props) => props.theme.fontColor};
`;
const SignUpButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.main};
  width: 52px;
  height: 52px;
  border-radius: 99px;
  margin-top: auto;
  cursor: pointer;
`;
const SubTitleWrap = styled.div`
  width: 600px;
  height: 30px;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  align-items: center;
  font-weight: 300;
  color: ${(props) => props.theme.fontColor};
`;
const LoginWrap = styled.div`
  background-color: #f3f7ff;
  width: 600px;
  height: 262px;
  margin-top: 22px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
`;
const Form = styled.form`
  width: 518px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;
const PasswordWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 48px;
  border: 1px solid #cfd9fe;
  border-radius: 6px;
  margin-bottom: 8px;
  text-indent: 16px;

  ::placeholder {
    color: #acb3b9;
    font-size: 14px;
    font-weight: 300;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.main};
  }
`;
const LoginButton = styled.button`
  height: 48px;
  margin-top: 9px;
  background-color: ${(props) => props.theme.main};
  color: white;
  font-size: 16px;
  font-weight: 300;
  border: 0;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
`;
const SplitLine = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 25px;
  margin-bottom: 24px;
  width: 600px;
  height: 1px;
`;
const GoogleButton = styled.div`
  width: 600px;
  height: 54px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
`;
const ForgetButton = styled.div`
  margin-top: 60px;
  width: 600px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 18px;
  font-weight: 400;
  border: 0;
  color: #0e85ec;
  text-decoration: underline;
`;
const GoogleAds = styled.div`
  width: 790px;
  height: 129px;
  margin-bottom: 101px;
  border-radius: 8px;
`;
