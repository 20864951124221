import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import axiosInstance from './AxiosInstance';

function ChangeInfo({ onBackClick, nickname }: any) {
  const jwtToken = sessionStorage.getItem('jwtToken');
  const email = sessionStorage.getItem('email');
  const [text, setText] = useState(nickname || '');
  const [isLoading, setIsLoading] = useState(false);
  console.log(nickname);

  const onSubmitClick = async () => {
    if (text.length === 0) {
      if (isMobile) {
        alert('변경할 닉네임을 입력해주세요.');
        return;
      } else {
        toast('변경할 닉네임을 입력해주세요.');
        return;
      }
    }
    if (text === nickname) {
      if (isMobile) {
        alert('같은 닉네임으로 변경할 수 없습니다.');
        return;
      } else {
        toast('같은 닉네임으로 변경할 수 없습니다.');
        return;
      }
    }
    setIsLoading(true);
    const response = await axiosInstance
      .post(
        `/user/nickname?email=${email}&currentNickname=${nickname}&changeNickname=${text}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .finally(() => setIsLoading(false));
    if (response.status === 200) {
      sessionStorage.setItem('nickname', text);
      window.dispatchEvent(new Event('storage'));
      // setText('');
      if (isMobile) {
        alert('변경되었습니다.');
        return;
      } else {
        toast('변경되었습니다.');
        return;
      }
    }
  };

  return (
    <div>
      <div className="h-12 flex items-center">
        <span className="text-lg font-light mr-[121px]">E-Mail</span>
        <div className="text-[#90a2b1] text-sm flex items-center bg-[#e7eff6] bg-opacity-50 w-[250px] h-full rounded-md border-2 border-[#c1d6e9] outline-none indent-4">
          {email?.split('@')[0]}
        </div>
        <span className="text-[#74828f] text-lg font-light mx-1">@</span>
        <div className="text-[#90a2b1] text-sm flex items-center bg-[#e7eff6] bg-opacity-50 w-[250px] h-full rounded-md border-2 border-[#c1d6e9] outline-none indent-4">
          {email?.split('@')[1]}
        </div>
      </div>
      <div className="h-12 flex items-center mt-7">
        <span className="text-lg font-light mr-[121px]">닉네임</span>
        <input
          autoFocus
          required
          maxLength={12}
          // placeholder={nickname!!}
          value={text}
          onChange={(e) => setText(e.target.value)}
          className=" w-[250px] h-full rounded-md border-2 border-[#c1d6e9] outline-none indent-4"
        />
        <div className="flex items-center">
          <ExclamationCircleIcon className="h-5 ml-2 mr-1 text-[#74828f]" />
          <span className="text-[13px] text-[#74828f]">
            12자 이내의 한글, 영어, 숫자만 가능
          </span>
        </div>
      </div>
      <div className="mt-7 h-11 flex items-center justify-end">
        <div
          onClick={onSubmitClick}
          className="cursor-pointer mr-7 w-[120px] h-full rounded flex justify-center items-center bg-[#0a2996] text-white"
        >
          {isLoading ? <ClipLoader size={20} color="white" /> : '확인'}
        </div>
        <div
          onClick={onBackClick}
          className="cursor-pointer w-[120px] h-full rounded flex justify-center items-center bg-[#b4bfc9] text-white"
        >
          돌아가기
        </div>
      </div>
      <div className="border-[1px] border-[#d9d9d9] pl-9 pt-5 mt-9 h-[114px] bg-[#ff9d27] bg-opacity-5 text-sm">
        <p className="text-[#54595e] font-bold">회원 등급 인증 안내</p>
        <div className="text-[#54595e] text-opacity-80 mr-9 mt-[6px] tracking-tighter">
          한의사, 한의대생 등급으로 변경을 원하시면{' '}
          <span className="text-[#188aef]">admin@acupedia.net</span>으로 다음의
          내용을 기입하여 <span className="text-[#fb6c05]">추가 인증 자료</span>
          를 보내주시면 확인 후 진행해드립니다. 자세한 내용은 이용약관을
          참고해주세요. (닉네임, 가입 이메일, 면허증/학생증 사본)
        </div>
      </div>
    </div>
  );
}

export default ChangeInfo;
