import React from 'react';

function LibraryRule() {
  return (
    <div className="border-t-2 border-[#D9E1E8] pt-2 ">
      <div className="mt-2 text-sm w-full flex flex-col text-right">
        <span>본 컨텐츠는 저작권법에 따라 보호받는 저작물입니다.</span>
        <span>무단 사용을 금합니다.</span>
      </div>
    </div>
  );
}

export default LibraryRule;
