import axiosInstance from 'components/AxiosInstance';
import React, { useState } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import styled from 'styled-components';

function FindAccount() {
  const [isSend, setIsSend] = useState(false);
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onResetClick = async () => {
    if (text.length === 0 || !text.includes('@')) {
      if (isMobile) {
        alert('이메일을 입력해주세요.');
        return;
      } else {
        toast('이메일을 입력해주세요.');
        return;
      }
    }
    setIsLoading(true);
    const response = await axiosInstance
      .post(`/user/password/link?email=${text}`)
      .finally(() => setIsLoading(false));
    if (response.status === 200) {
      setIsSend(true);
    }
  };
  return (
    <Container>
      <ContentWrap>
        <Title>계정 찾기</Title>
        {!isSend ? (
          <InputWrap>
            <EmailInputWrap>
              <span>E-mail</span>
              <input
                required
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="이메일을 입력해주세요."
              ></input>
            </EmailInputWrap>
            <ResetButton onClick={onResetClick}>
              {isLoading ? <ClipLoader size={20} color="white" /> : '계정 초기화하기'}
            </ResetButton>
          </InputWrap>
        ) : (
          <SendTextWrap>
            <p>E-mail로 초기화 링크를 전송하였습니다.</p>
            <p>메일함을 확인해주세요.</p>
          </SendTextWrap>
        )}
        <InfoWrap>
          <InfoLink to={'/login'}>로그인 하기로 돌아가기</InfoLink>
          <span>/</span>
          <InfoLink to={'/createAccount'}>계정 생성하기</InfoLink>
        </InfoWrap>
      </ContentWrap>
    </Container>
  );
}

export default FindAccount;

const Container = styled.div`
  width: ${isBrowser ? '' : '1440px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;
const ContentWrap = styled.div`
  width: 575px;
`;
const Title = styled.span`
  font-size: 54px;
  font-weight: 600;
  color: ${(props) => props.theme.fontColor};
`;
const InputWrap = styled.div`
  height: 111px;
  margin-top: 27px;
  margin-bottom: 60px;
`;
const SendTextWrap = styled.div`
  margin-top: 57px;
  margin-bottom: 60px;
  height: 72px;
  color: #9099b1;
  font-size: 24px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const InfoWrap = styled.div`
  height: 27px;
  display: flex;
  justify-content: center;
  justify-content: space-between;
  span {
    color: #c4c4c4;
    font-size: 18px;
  }
`;
const InfoLink = styled(Link)`
  color: #0e85ec;
  font-size: 18px;
  font-weight: 400;
  width: 247.65px;
  display: flex;
  justify-content: center;
`;
const EmailInputWrap = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  span {
    font-size: 18px;
    font-weight: 500;
    color: #acb3b9;
    letter-spacing: 1.04px;
  }
  input {
    margin-left: auto;
    width: 467.7px;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #cfd9fe;
    text-indent: 16px;

    ::placeholder {
      color: #acb3b9;
      font-size: 14px;
      font-weight: 300;
    }

    &:focus {
      outline: none;
      border-color: ${(props) => props.theme.main};
    }
  }
`;
const ResetButton = styled.div`
  width: 189px;
  height: 48px;
  background-color: ${(props) => props.theme.main};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
  margin-top: 15px;
  cursor: pointer;
`;
