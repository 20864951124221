import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { Adsense } from '@ctrl/react-adsense';
import SearchPrescription from './SearchPrescription';
import GoogleAdvertise from './GoogleAdvertise';
import { VERTICAL } from 'constants/GoogleAds';
import { useQuery } from 'react-query';
import { categoryAPi, wikiApi } from 'libs/api';
import makeStyles from '@mui/material/styles/makeStyles';

interface Category {
  id: string;
  name: string;
  children: Subcategory[];
  libraryHistories: LibraryHistory[];
}

interface Subcategory {
  id: string;
  name: string;
  children: never[]; // an empty array since subcategories do not have any children
  libraryHistories: LibraryHistory[];
}

interface LibraryHistory {
  id: number;
  subTitle: string;
  title: string;
  documentOrder: number;
}

function SideComponent() {
  const location = useLocation();
  const navigation = useNavigate();
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [curLocation, setCurLocation] = useState('');
  const [item, setItem] = useState('');
  const [expanded, setExpanded] = useState<string[]>([]);
  const word = decodeURIComponent(
      location?.search?.split('?')[1].split('&')[0].split('=')[1]
  );

  const { data, isLoading } = useQuery(
      'wikiRecentChanges',
      wikiApi.wikiRecentChanges
  );

  const { data: categoryData, isLoading: categoryLoading } = useQuery(
      'libraryIndex',
      categoryAPi.libraryIndex
  );

  const onPageClick = (title: string) => {
    if (title === '') return;

    navigation({
      pathname: '/wikiDetail',
      search: `?title=${title}`,
    });
  };

  const onLibraryPageClick = (title: string) => {
    if (title === '') return;

    navigation({
      pathname: '/libraryDetail',
      search: `?title=${title}`,
    });
  };

  const onSubTitleClick = (title: string) => {
    navigation({ pathname: '/libraryDetail', search: `?title=${title}` });
  };

  useEffect(() => {
    if (location.pathname !== curLocation) {
      setCurLocation(location.pathname);
    }
  }, [location]);

  const getTreeItemsFromData = (treeItems: any) => {
    return treeItems.map((treeItemData: any) => {
      let children = undefined;
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children);
      }

      const libraryHistoryNodes = treeItemData.libraryHistories?.map(
          (history: LibraryHistory) => (
              <TreeItem
                  key={history.id}
                  nodeId={history.id.toString()}
                  label={history.subTitle}
                  onClick={() => onSubTitleClick(history.title)}
                  style={{ fontSize: '10px' }}
              />
          )
      );

      return (
          <TreeItem
              key={treeItemData.id}
              nodeId={treeItemData.id}
              label={treeItemData.name}
          >
            {children}
            {libraryHistoryNodes}
          </TreeItem>
      );
    });
  };

  const handleSelect = (e: React.SyntheticEvent, name: string) => {
    // Check if the node is already expanded
    const isExpanded = expanded.includes(name);

    // If the node is not expanded, add it to the expanded array
    // If the node is already expanded, remove it from the expanded array
    setExpanded((prevExpanded) =>
        isExpanded
            ? prevExpanded.filter((item) => item !== name)
            : [...prevExpanded, name]
    );

    // Do your other logic for node selection here
    setItem(name);
  };

  const DataTreeView = ({ treeItems }: any) => {
    return (
        <TreeView
            onNodeSelect={handleSelect}
            expanded={expanded}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ width: 226, height: 600 }}
        >
          {getTreeItemsFromData(treeItems)}
        </TreeView>
    );
  };

  return (
      <Container>
        {location.pathname.includes('/library') ||
        location.pathname.includes('/prescription') ||
        location.pathname.includes('/acupoints') ||
        location.pathname.includes('/libraryDetail') ? (
            <ModifyWrap>
              <div className="w-full h-[1px] bg-[#d8dce0]" />
              <p className="display-block w-full h-[36px] bg-[#F4F5F4] flex items-center justify-center text-[16px]">
                학술 자료庫 목록
              </p>
              <div className="w-full h-[1px] bg-[#d8dce0]" />
              <ScrollDiv>
                {categoryLoading ? null : (
                    <DataTreeView treeItems={categoryData.data} />
                )}
              </ScrollDiv>
              <div className="w-full h-[1px] bg-[#d8dce0]" />
            </ModifyWrap>
        ) : (
            <>
              <ModifyWrap>
                <p className="font-bold text-[16px] w-full h-[56px] bg-[#F4F5F4] flex items-center justify-center fontcolor">
                  최근 변경된 위키 페이지
                </p>
                {isLoading
                    ? [...Array(8)].map((item, index) => {
                      return (
                          <div key={index} className="h-[40px] flex flex-col w-full ">
                            <div className="w-full h-[1px] bg-[#d8dce0]" />
                            <p className="indent-4 mt-auto mb-auto cursor-pointer text-[15px]"></p>
                          </div>
                      );
                    })
                    : [...Array(8)].map((_, index: number) => {
                      return (
                          <div
                              onClick={() =>
                                  onPageClick(
                                      data.data.wikis[index]
                                          ? data.data.wikis[index].wikiTitle
                                          : ''
                                  )
                              }
                              key={index}
                              className="h-[40px] flex flex-col w-full "
                          >
                            <div className="w-full h-[1px] bg-[#d8dce0]" />
                            <p className="indent-4 mt-auto mb-auto cursor-pointer text-[15px] text-ellipsis overflow-hidden whitespace-nowrap">
                              {data.data.wikis[index]
                                  ? data.data.wikis[index].wikiTitle
                                  : ''}
                            </p>
                          </div>
                      );
                    })}

                <div className="w-full h-[1px] bg-[#d8dce0]" />
              </ModifyWrap>
              <div className="w-full h-[25px]" />
              {location.pathname.includes('/searchResult') ? (
                  <ModifyWrap>
                    <p className="font-bold text-[16px] w-full h-[56px] bg-[#F4F5F4] flex items-center justify-center fontcolor">
                      최근 작성된 학술자료庫 페이지
                    </p>
                    {isLoading
                        ? [...Array(8)].map((item, index) => {
                          return (
                              <div
                                  key={index}
                                  className="h-[40px] flex flex-col w-full "
                              >
                                <div className="w-full h-[1px] bg-[#d8dce0]" />
                                <p className="indent-4 mt-auto mb-auto cursor-pointer text-[15px]"></p>
                              </div>
                          );
                        })
                        : [...Array(8)].map((_, index: number) => {
                          return (
                              <div
                                  onClick={() =>
                                      onLibraryPageClick(
                                          data.data.libraries[index]
                                              ? data.data.libraries[index].libraryTitle
                                              : ''
                                      )
                                  }
                                  key={index}
                                  className="h-[40px] flex flex-col w-full "
                              >
                                <div className="w-full h-[1px] bg-[#d8dce0]" />
                                <p className="indent-4 mt-auto mb-auto cursor-pointer text-[15px] text-ellipsis overflow-hidden whitespace-nowrap">
                                  {data.data.libraries[index]
                                      ? data.data.libraries[index].libraryTitle
                                      : ''}
                                </p>
                              </div>
                          );
                        })}

                    <div className="w-full h-[1px] bg-[#d8dce0]" />
                  </ModifyWrap>
              ) : null}
            </>
        )}
        {/* 검색결과 화면일때만 침구처방 검색 나오지 않게 */}
        {location.pathname.includes('/searchResult') ? null : (
            <div className="mt-[45px]">
              <SearchPrescription />
            </div>
        )}

        <GoogleAds>
          <GoogleAdvertise slot={VERTICAL} width={136} height={888} />
        </GoogleAds>
      </Container>
  );
}

export default SideComponent;

const Container = styled.div`
  width: 236px;
  height: 1459px;
`;
const ModifyWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
`;
const GoogleAds = styled.div`
  height: 888px;
  border-radius: 8px;
  margin-top: 132px;
`;
const ScrollDiv = styled.div`
  padding-top: 7px;
  overflow-y: auto;
  height: 610px;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 6px solid rgba(0, 0, 0, 0.18);
    border-left: 0;
    border-right: 0;
    background-color: #888888;
  }
`;
