import { Editor } from '@tinymce/tinymce-react';
import axiosInstance from 'components/AxiosInstance';
import { Dispatch, SetStateAction, useState } from 'react';

interface Props {
  oldString: string;
  setNewString: Dispatch<SetStateAction<string>>;
}

interface BlobInfo {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
}

function TinyMCEEditor({ oldString, setNewString }: Props) {
  const [editor, setEditor] = useState({ html: '', md: '' });
  const jwtToken = sessionStorage.getItem('jwtToken');

  const handleEditorChange = (content: string, editor: any) => {
    setEditor({ html: content, md: editor.getContent() });
    setNewString(editor.getContent());
  };

  interface UploadFailure {
    message: string;
    remove?: boolean;
  }
  
  type ProgressFn = (percent: number) => void;
  type UploadHandler = (blobInfo: BlobInfo, progress: ProgressFn) => Promise<string>;

  const example_image_upload_handler : UploadHandler = (blobInfo: any, progress: ProgressFn) => new Promise((resolve, reject) => {

    var image_name = blobInfo.blob().filename;

    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', '/upload_image');

    xhr.upload.onprogress = (e) => {
    progress(e.loaded / e.total * 100);
    };

    xhr.onload = () => {
    if (xhr.status === 403) {
        reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
        return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
        reject('HTTP Error: ' + xhr.status);
        return;
    }

    const json = JSON.parse(xhr.responseText);

    if (!JSON || typeof json.location != 'string') {
        reject('Invalid JSON: ' + xhr.responseText);
        return;
    }

    resolve(json.location);
    };

    xhr.onerror = () => {
    reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };

    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
    xhr.send(formData);
    });


  return (
    <Editor
      apiKey="ablbmf6tndkwhkcqx9m0u9x20jm8xakx56kluvrf76eb7ka3"
      initialValue="여기에 편집을 해보시기 바랍니다.

      워드와 비슷한 편집기이며, markdown 문법이 아닌 순수 HTML tag를 이용한 편집기입니다.
      
      작성의 간편함은 적을 수 있으나 조금 더 구체적이고 복잡한 작업을 진행할 수 있습니다."
      onEditorChange={handleEditorChange}
      init={{
        language: 'ko_KR', //한글판으로 변경
        height: 600,
        menubar: true,
        paste_as_text: true,
        images_upload_handler: example_image_upload_handler,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'print',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'paste',
          'code',
          'help',
          'wordcount',
          'save',
        ],
        toolbar:
          ' forecolor backcolor |' +
          ' fontsize fontfamily |' +
          ' bold italic underline strikethrough |' +
          ' alignjustify alignleft aligncenter alignright |' +
          ' bullist numlist |' +
          ' table tabledelete |' +
          ' link image',
        block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3',
        font_family_formats:
          '굴림체=Gulim;돋움체=Dotum;바탕체=Batang;궁서체=Gungsuh;나눔고딕=nanumgothic;나눔명조=Nanum Myoungjo;나눔붓글씨=nanumbrushscript',
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
        content_style:
          'body {font-family: nanumgothic, sans-serif; font-size: 14pt;}',
      }}
    />
  );
}

export default TinyMCEEditor;
