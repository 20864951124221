import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BsQuestionLg } from 'react-icons/bs';
import { FiArrowDown } from 'react-icons/fi';
import { isBrowser, isMobile } from 'react-device-detect';
import { SearchIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';
import { GiHamburgerMenu } from 'react-icons/gi';
import useComponentVisible from 'libs/useComponentVisible';
import { FAQContent, Menu, SignUpModal } from 'components';
import { useAppDispatch } from 'store';
import settingSlice from 'slices/setting';
import { userPopupAPi } from 'libs/api';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import MenuLeft from 'components/MenuLeft';
import SearchPrescriptionLayer from 'components/SearchPrescriptionLayer';
import Popup2 from 'components/Popup2'; 

function Home() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  const { register, handleSubmit } = useForm();
  const jwtToken = sessionStorage.getItem('jwtToken');
  const [faqOpen, setFaqOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const curLocation = location.pathname + location.search;

  const { data: popupList, isLoading: isLoading  } = useQuery(
    ['popupList', jwtToken],
    userPopupAPi.popupList
  );

  const onLogInClick = () => {
    dispatch(
      settingSlice.actions.setLastPageBeforeLogin({
        lastPageBeforeLogin: curLocation,
      })
    );
    navigation('/login');
  };
  const onSignUpClick = () => {
    setIsModalOpen(true);
    // navigation('/createAccount');
  };
  const onFaqClick = () => {
    setFaqOpen((prev) => !prev);
  };
  const onValid = (validForm: any) => {
    if (validForm.search.trim().length === 0) return;
    if (
      /[\{\}\[\]\/?.|\*~`!^\_+<>@\#$%&\\\=\'\"]/g.test(validForm.search.trim())
    ) {
      if (isMobile) {
        alert('특수문자를 포함할 수 없습니다.');
      } else {
        toast('특수문자를 포함할 수 없습니다.');
      }
      return;
    }
    console.log(validForm);
    navigation({
      pathname: 'searchResult',
      search: `?search=${encodeURIComponent(validForm.search)}`,
    });
  };

  const [isMenuRightVisible, setIsMenuRightVisible] = useState(false);
  const [isMenuLeftVisible, setIsMenuLeftVisible] = useState(false);

  const onMenuClick = () => {
    setIsMenuRightVisible((prev) => !prev);
  };
  
  const [isSearch, setIsSearch] = useState(false);

    return (
    <>
      
      <Container faqOpen={faqOpen}>
        <Header>
          {/* <span>KOR</span> */}
          {/* <span style={{ color: '#bcbcbc', cursor: 'default' }}>ENG</span> */}
	  {isLoading ? null : (
	    <Popup2 data={popupList}/>
	  )}
          {jwtToken ? (
            <>
                <HeadLeft>
                  <div className="relative">
                    <GiHamburgerMenu
                      onClick={() => setIsMenuLeftVisible(!isMenuLeftVisible)}
                      className="menu"
                      color="#0A2996"
                      id="menuLeft"
                    />
                    {isMenuLeftVisible && (
                      <MenuLeft
                        setIsMenuLeftVisible={setIsMenuLeftVisible}
                        setIsSearch={setIsSearch}
                        isSearch={isSearch}
                      />
                    )}
                  </div>
                  <img src="/images/new1.jpg" alt="logo" style={{ width: 210 }} />
                </HeadLeft>

            <SearchLayer>
              <SearchPrescriptionLayer
                  isSearch={isSearch}
                  setIsSearch={setIsSearch}
              />
            </SearchLayer>

              <div className="relative">
                <GiHamburgerMenu
                  onClick={onMenuClick}
                  className="menu"
                  color="#0A2996"
                  id="menuRight"
                />
                {isMenuRightVisible ? (
                  <Menu
                    setIsMenuRightVisible={setIsMenuRightVisible}
                  />
                ) : null}
              </div>              

            </>
          ) : (
            <>
              <HeadLeft>
                  <div className="relative">
                    <GiHamburgerMenu
                      onClick={() => setIsMenuLeftVisible(!isMenuLeftVisible)}
                      className="menu"
                      color="#0A2996"
                      id="menuLeft"
                    />
                    {isMenuLeftVisible && (
                      <MenuLeft
                        setIsMenuLeftVisible={setIsMenuLeftVisible}
                        setIsSearch={setIsSearch}
                        isSearch={isSearch}
                      />
                    )}
                  </div>
                  <img src="/images/new1.jpg" alt="logo" style={{ width: 210 }} />
                </HeadLeft>

            <SearchLayer>
              <SearchPrescriptionLayer
                  isSearch={isSearch}
                  setIsSearch={setIsSearch}
              />
            </SearchLayer>

              <ButtonGroup>
                <Button
                  onClick={onLogInClick}
                  style={{ color: 'white', backgroundColor: '#0A2996' }}
                >
                  로그인
                </Button>
                <Button onClick={onSignUpClick} style={{ color: '#0A2996' }}>
                  회원가입
                </Button>
              </ButtonGroup>
            </>
          )}
        </Header>
        <MainContent>
          <Logo
            src="/images/Acupedia_mainlogo(temp).png"
            alt="logo"
            title="이미지"
          />
          {/* <Slogan></Slogan> */}
          <form onSubmit={handleSubmit(onValid)}>
            <div className="relative flex ">
              <Input
                {...register('search')}
                placeholder="검색어를 입력해주세요."
              />
              <div
                onClick={handleSubmit(onValid)}
                className="cursor-pointer bg-[#188AEF] w-[50px] absolute right-0 top-0 bottom-0 my-auto flex items-center justify-center rounded-r-[8px]"
              >
                <SearchIcon className="h-6 text-white" />
              </div>
            </div>
          </form>

          {/* <HelpIcon onClick={onFaqClick} faqOpen={faqOpen}>
            {faqOpen ? (
              <FiArrowDown color="#ff8a00" className="icon" />
            ) : (
              <BsQuestionLg color="white" className="icon" />
            )}
          </HelpIcon>
            {!faqOpen ? <HelpTitle>아큐피디아에 처음이신가요?</HelpTitle> : null}*/}
        </MainContent>
        {/*faqOpen ? <FAQContent /> : null*/}
        {/* <SplitLine /> */}
      </Container>
      <SignUpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
}

export default Home;

const Container = styled.div<{ faqOpen: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 200px;

  .menu {
    margin-left: auto;
    width: 32px;
    height: 24px;
    cursor: pointer;
  }
  .icon {
    width: ${(props) => (props.faqOpen ? '28px' : '18px')};
    height: ${(props) => (props.faqOpen ? '28px' : '18px')};
  }
  .faq {
    margin-top: 74px;
    font-size: 16px;
    font-weight: 300;
    color: ${(props) => props.theme.fontColor};
  }
  .title {
    margin-top: 8px;
    font-size: 38px;
    font-weight: 600;
    color: #ff8a00;
  }
  .subtitle {
    margin-top: 16px;
    font-size: 16px;
    color: ${(props) => props.theme.fontColor};
  }
`;

const Header = styled.nav`
  position: sticky;
  top: 0;
  width: ${isBrowser ? '' : '1440px'};
  /* width: 1440px; */
  height: 76px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* align-self: center; */
  padding-right: 57px;
  padding-left: 57px;
  z-index:1;

  span {
    width: 32px;
    margin-right: 19px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
`

const Button = styled.div`
  width: 107px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0a2996;
  border-radius: 6px;
  margin-left: 19px;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
`;
const MainContent = styled.main`
  width: ${isBrowser ? '' : '1440px'};
  /* height: 583px; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Logo = styled.img`
  margin-top: 50px;
  margin-bottom: 42px;
  width: 400px;
  height: 400px;
`;
const Slogan = styled.div`
  margin-top: 27px;
  margin-bottom: 50px;
  width: 491px;
  height: 30px;
  background-color: red;
  visibility: hidden;
`;
const Input = styled.input`
  width: 420px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #c1d6e9;
  text-indent: 16px;

  ::placeholder {
    color: #84aed3;
    font-size: 16px;
    font-weight: 300;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.main};
  }
`;
const HelpIcon = styled.div<{ faqOpen: boolean }>`
  margin-top: 49px;
  width: 48px;
  height: 48px;
  border-radius: 99px;

  background-color: ${(props) => (props.faqOpen ? 'white' : '#ff8a00')};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HelpTitle = styled.span`
  margin-top: 11px;
  width: 491px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  color: '#182f43';
  opacity: 0.5;
`;

const SplitLine = styled.div`
  width: 1110px;
  height: 1px;
  background-color: #ebf2f8;
  margin: auto;
  margin-top: 107px;
  margin-bottom: 206px;
`;

const HeadLeft = styled.div`
    display:flex; 
    align-items:center;
    gap:10px;
`;

const SearchLayer = styled.div`
    position:absolute; 
    top:100px;
    left:57px;
    z-index:100;
`;






