import { Helmet } from 'react-helmet-async';

function HelmetComponent() {
  return (
    <Helmet>
      <title>아큐피디아</title>
      <link
        rel="preload"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&amp;display=swap"
        as="style"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&amp;display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
  );
}

export default HelmetComponent;
