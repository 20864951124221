import React from 'react';
import { isBrowser } from 'react-device-detect';
import styled from 'styled-components';

function Footer() {
  return (
    <Container>
      <Content>
        <div className="flex w-[1074px] items-center justify-between">
          <span className="text-[#0A2996] text-5xl font-semibold mr-[72px]">
            ACUPEDIA
          </span>
          <div className="flex justify-between w-[690px]">
            <div className="w-[320px] h-[81px] text-[#182F43] flex flex-col">
              <p className="text-lg font-semibold">About Us</p>
              <div className="mt-[10px]">
                <p className="text-sm">
                  대전대학교 한의과대학 경락경혈학교실 동문회
                </p>
                <p className="text-sm">국제한의학회 (ISTKM) : 대표 임윤경</p>
                <p className="text-sm">사업자번호 : 618-82-61992</p>
                <p className="text-sm">
                  Copyright © acupedia All right Reserved
                </p>
              </div>
            </div>
            <div className="w-[154px] h-[81px] text-[#182F43] flex flex-col">
              <p className="text-lg font-semibold">Contact Us</p>
              <p className="text-sm mt-[10px]">admin@acupedia.net</p>
              <p className="text-sm mt-[10px]">경기도 용인시 기흥구</p>
              <p className="text-sm">동백죽전대로 527번길</p>
              <p className="text-sm">98-2, 302호</p>
            </div>
            <div className="w-[154px] h-[81px] text-[#182F43]">
              <p className=" text-lg font-semibold">Policy</p>
              <div className="flex flex-col">
                <a className="text-sm text-[#182F43] mt-[10px]" href="/terms">
                  이용약관
                </a>
                <a className="text-sm text-[#182F43]" href="/privacy">
                  개인정보처리방침
                </a>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </Container>
  );
}

export default Footer;

const Container = styled.div`
  width: ${isBrowser ? '100%' : '1440px'};
  height: 322px;
  background-color: ${(props) => props.theme.footer};
  display: flex;
  justify-content: center;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const Content = styled.div`
  width: 1440px;
  background-color: #edf6fe;
  display: flex;
  justify-content: center;
  align-items: center;
`;
