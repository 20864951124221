import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import axiosInstance from './AxiosInstance';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: any;
}

function SignUpModal({ isModalOpen, setIsModalOpen }: Props) {
  const { lastPageBeforeLogin } = useAppSelector((state) => state.setting);
  const navigation = useNavigate();

  const onSignUpClick = () => {
    setIsModalOpen(false);

    navigation('/createAccount');
  };

  const successGoogle = async (res: any) => {
    setIsModalOpen(false);

    console.log(res);
    const formData = new FormData();
    formData.append('code', res?.code);
    formData.append(
      'client_id',
      '350409008095-sfstjhbcmv7g7te156d5p2c18l13u1eg.apps.googleusercontent.com'
    );
    formData.append('client_secret', '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');
    formData.append(
      'redirect_uri',
      process.env.NODE_ENV == 'production'
        ? 'https://www.acupedia.net'
        : 'http://localhost:3000'
    );
    formData.append('grant_type', 'authorization_code');

    const response = await axiosInstance.post(
      'https://oauth2.googleapis.com/token',
      formData
    );
    console.log(response);

    if (response.status === 200) {
      const response2 = await axiosInstance.get(
        `https://www.googleapis.com/userinfo/v2/me?access_token=${response.data.access_token}`
      );
      if (response2.status === 200) {
        console.log(response2);

        const response3 = await axiosInstance.post('/authenticate/google', {
          email: response2.data.email,
          nickname: response2.data.name
        });
        console.log(response3);

        if (response3.status === 200) {
          sessionStorage.setItem('jwtToken', response3.data.data.jwtToken);
          sessionStorage.setItem('nickname', response3.data.data.nickname);
          sessionStorage.setItem('email', response3.data.data.email);
          sessionStorage.setItem('authority', response3.data.data.authority);
          if (response3.status === 200) {
            if (lastPageBeforeLogin) {
              navigation(lastPageBeforeLogin);
            } else {
              navigation('/');
            }
          }
        }
      }
      console.log(response2);
    }
  };

  const onGoogleClick = useGoogleLogin({
    onSuccess: successGoogle,
    onError: () => setIsModalOpen(false),
    flow: 'auth-code'
  });

  return (
    <Modal
      ariaHideApp={false}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999
        },
        content: {
          width: '465px',
          height: '180px',
          display: 'flex',
          margin: 'auto',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '8px',
          outline: 'none',
          flexDirection: 'column'
        }
      }}
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
    >
      <div className="flex flex-col items-center">
        <div
          onClick={onSignUpClick}
          className="cursor-pointer rounded-[4px] mt-[10px] w-[385px] h-[44px] flex justify-center items-center text-[14px] font-bold text-white bg-[#183BB7]"
        >
          아큐피디아 계정 생성하기
        </div>
        <div
          onClick={onGoogleClick}
          className="border-opacity-10 border-black border-[1px] cursor-pointer rounded-[10px] mt-[10px] w-[385px] h-[44px] flex justify-center items-center text-[14px] font-bold "
        >
          <div className="flex items-center">
            <FcGoogle className="h-6 w-6" />
            <span className="ml-4 text-[20px] font-medium text-opacity-50 text-black">
              Continue with Google
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SignUpModal;
