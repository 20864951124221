import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import axiosInstance from './AxiosInstance';

function ChangePassword({ onBackClick }: any) {
  const navigation = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  const [passwordType1, setPasswordType1] = useState('password');
  const [passwordType2, setPasswordType2] = useState('password');
  const [passwordType3, setPasswordType3] = useState('password');
  const [isLoading, setIsLoading] = useState(false);
  const email = sessionStorage.getItem('email');
  const jwtToken = sessionStorage.getItem('jwtToken');

  const clickPasswordIcon1 = () => {
    setPasswordType1(passwordType1 === 'password' ? 'text' : 'password');
  };
  const clickPasswordIcon2 = () => {
    setPasswordType2(passwordType2 === 'password' ? 'text' : 'password');
  };
  const clickPasswordIcon3 = () => {
    setPasswordType3(passwordType3 === 'password' ? 'text' : 'password');
  };
  const onValid = async (validForm: any) => {
    console.log(validForm);
    if (validForm.newPassword !== validForm.checkPassword) {
      if (isMobile) {
        alert('비밀번호를 다시 확인해주세요.');
      } else {
        toast('비밀번호를 다시 확인해주세요.');
      }
      return;
    }
    setIsLoading(true);
    const response = await axiosInstance
      .post(
        `/user/password/change?&email=${email}&currentPassword=${validForm.originPassword}&changePassword=${validForm.newPassword}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`
          }
        }
      )
      .finally(() => setIsLoading(false));
    if (response.status === 200) {
      reset();
      if (isMobile) {
        alert('비밀번호를 변경하였습니다.');
      } else {
        toast('비밀번호를 변경하였습니다.');
      }
      // navigation('/');
    }
  };

  return (
    <div className="pl-[100px]">
      <form onSubmit={handleSubmit(onValid)}>
        <div className="w-[510px] h-[188px] flex flex-col justify-between">
          <PasswordWrap>
            <span className="text-sm font-medium text-[#acb3b9]">기존 비밀번호</span>
            <Input
              {...register('originPassword', { required: true })}
              type={passwordType1}
              placeholder="기존 비밀번호를 입력해주세요."
            ></Input>
            {passwordType1 === 'password' ? (
              <BsEyeSlash onClick={clickPasswordIcon1} className="password" />
            ) : (
              <BsEye onClick={clickPasswordIcon1} className="password" />
            )}
          </PasswordWrap>
          <PasswordWrap>
            <span className="text-sm font-medium text-[#acb3b9]">신규 비밀번호</span>
            <Input
              {...register('newPassword', { required: true })}
              type={passwordType2}
              placeholder="신규 비밀번호를 입력해주세요."
            ></Input>
            {passwordType2 === 'password' ? (
              <BsEyeSlash onClick={clickPasswordIcon2} className="password" />
            ) : (
              <BsEye onClick={clickPasswordIcon2} className="password" />
            )}
          </PasswordWrap>
          <PasswordWrap>
            <span className="text-sm font-medium text-[#acb3b9]">비밀번호 확인</span>
            <Input
              {...register('checkPassword', { required: true })}
              type={passwordType3}
              placeholder="비밀번호를 다시 입력해주세요."
            ></Input>
            {passwordType3 === 'password' ? (
              <BsEyeSlash onClick={clickPasswordIcon3} className="password" />
            ) : (
              <BsEye onClick={clickPasswordIcon3} className="password" />
            )}
          </PasswordWrap>
        </div>

        <div className="mt-7 h-11 flex items-center justify-end mr-20">
          <button className="cursor-pointer mr-7 w-[120px] h-full rounded flex justify-center items-center bg-[#0a2996] text-white">
            {isLoading ? <ClipLoader size={20} color="white" /> : '확인'}
          </button>
          <div
            onClick={onBackClick}
            className="cursor-pointer w-[120px] h-full rounded flex justify-center items-center bg-[#b4bfc9] text-white"
          >
            돌아가기
          </div>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
const PasswordWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .password {
    position: absolute;
    width: 17px;
    height: 17px;
    right: 24px;
    cursor: pointer;
    color: #dadada;
    margin-top: -7px;
  }
`;

const Input = styled.input`
  width: 396px;
  height: 48px;
  border: 1px solid #cfd9fe;
  border-radius: 6px;
  margin-bottom: 8px;
  text-indent: 16px;

  ::placeholder {
    color: #acb3b9;
    font-size: 14px;
    font-weight: 300;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.main};
  }
`;
