import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from './AxiosInstance';

function Exit({ onBackClick }: any) {
  const navigation = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const email = sessionStorage.getItem('email');
  const jwtToken = sessionStorage.getItem('jwtToken');

  const onCheckClick = () => {
    setIsChecked((prev) => !prev);
  };
  const onExitClick = async () => {
    if (!isChecked) {
      if (isMobile) {
        alert('동의사항에 체크해주세요.');
      } else {
        toast('동의사항에 체크해주세요.');
      }
      return;
    }
    if (window.confirm('정말 탈퇴하시겠습니까?')) {
      const response = await axiosInstance.post(
        `/user/withdrawal`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`
          }
        }
      );
      if (response.status === 200) {
        if (isMobile) {
          alert('탈퇴되었습니다.');
        } else {
          toast('탈퇴되었습니다.');
        }
      }
      sessionStorage.clear();
      navigation('/');
    }
  };
  return (
    <div className="flex flex-col items-center">
      <div className="pl-[43px] pt-[42px] pr-[44px] w-full border-[1px] border-[#d9d9d9] h-[437px] bg-[#fbfdff] bg-opacity-60">
        <div className="tracking-tighter text-[17px] font-light whitespace-pre-line">
          {`아큐피디아 사용에 진심으로 감사드립니다.
            회원탈퇴 전 반드시 유의사항을 확인하고 진행부탁드립니다.`}
        </div>
        <div className="h-[1px] w-[72px] bg-black mt-[13px]" />
        <div className="text-sm font-light mt-[35px]">
          <p className="font-bold">개인정보 및 서비스 이용기록 삭제</p>
          <p className="tracking-tighter">
            개인정보 및 개인 서비스 이용기록이 모두 삭제 되어 삭제된 데이터는 복구되지
            않습니다. 필요한 데이터는 미리 백업해 주시기 바랍니다.
          </p>
        </div>
        <div className="text-sm font-light mt-[25px]">
          <p className="font-bold">소셜 계정 연결 정보 삭제</p>
          <p className="tracking-tighter">
            소셜 계정에 ID를 연결한 경우 탈퇴 시 연결 정보도 함께 삭제됩니다.
          </p>
        </div>
        <div className="text-sm font-light mt-[25px]">
          <p className="font-bold">위키 등록 게시물 유지</p>
          <p className="tracking-tighter">
            회원가입 이후 등록하신 게시물들은 회원탈퇴 후에도 삭제 되지 않고 유지됩니다.
          </p>
        </div>
        <div className="text-sm font-light mt-[25px]">
          <p className="font-bold">탈퇴 후 제한</p>
          <p className="tracking-tighter">
            회원탈퇴 처리 후에는 회원님의 개인정보를 복원할 수 없으며, 회원탈퇴 진행 시
            해당 아이디는 영구적으로 삭제되어 재가입이 불가합니다.
          </p>
        </div>
      </div>

      <div className="mt-7 flex items-center">
        <input
          required
          checked={isChecked}
          onChange={onCheckClick}
          type={'checkbox'}
          className="w-5 h-5"
        />
        <span className="ml-[7px] text-sm">
          회원탈퇴 시 유의사항을 확인하였으며, 모두 동의합니다.
        </span>
      </div>
      <div className="flex mt-8">
        <div
          onClick={onExitClick}
          className="cursor-pointer rounded-md w-[100px] h-[35px] bg-[#b4bfc9] text-white text-[15px] font-medium flex justify-center items-center"
        >
          탈퇴하기
        </div>
        <div
          onClick={onBackClick}
          className="cursor-pointer ml-[35px] w-[42px] h-[30px] border-b-2 border-[red] text-[red] flex justify-center items-center text-lg font-light"
        >
          취소
        </div>
      </div>
    </div>
  );
}

export default Exit;
