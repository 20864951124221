import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { IoAlertCircleOutline } from 'react-icons/io5';
import CreatableSelect from 'react-select/creatable';
import { isBrowser, isMobile } from 'react-device-detect';
import { cls } from 'libs/utils';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { useDebounce } from 'use-debounce';
import ClipLoader from 'react-spinners/ClipLoader';
import axiosInstance from 'components/AxiosInstance';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store';
import settingSlice from 'slices/setting';

interface EnterForm {
  email?: string;
  phone?: string;
}

function CreateAccount() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const { register, handleSubmit, reset, control, watch } = useForm();
  const observer = watch(['email', 'backEmail', 'nickname']);
  const [isCap, setIsCap] = useState('');
  const [isDirect, setIsDirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [canEmailUse, setCanEmailUse] = useState(false);
  const [canNicknameUse, setCanNicknameUse] = useState(false);
  const [curNickname, setCurNickname] = useState('');
  const [curEmail, setCurEmail] = useState({
    email: '',
    backEmail: '',
  });
  const [debounceValue] = useDebounce(observer, 2000);
  const curLocation = location.pathname + location.search;
  const [code, setCode] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifySend, setIsVerifySend] = useState(false);
  const initialTime = 180;
  const [remainingTime, setRemainingTime] = useState(0);

  const sendVerificationCode = async () => {
    if (
      debounceValue[0]?.trim().length > 0 &&
      debounceValue[1]?.trim().length > 0
    ) {

      setLoading(true);
      const response = await axiosInstance
        .post(`/user/verifyEmail/send?email=${debounceValue[0]?.trim()}@${debounceValue[1]?.trim()}`)
        .finally(() => setLoading(false));
      if (response.status === 200) {
        if (response.data.data === '이메일 인증 코드전송 성공') {
          if (isMobile) {
            alert('코드가 발송되었습니다.');
          } else {
            toast('코드가 발송되었습니다.');
          }
          setIsVerifySend(true);
	  setRemainingTime(initialTime);
	}
      }
    }
  };

  useEffect(() => {

    // useEffect를 사용하여 컴포넌트가 마운트될 때 타이머 시작.
    const timer = setInterval(() => {
      // 남은 시간이 0보다 크면 1초씩 감소시킴.
      if (remainingTime > 0) {
	      setRemainingTime((prevTime) => prevTime - 1);
      } else {
        //인증하기 전에 시간 종료되면 메일 발송부터 다시 해야함.
        if(!isVerified){
          setIsVerifySend(false);
        }
        // 남은 시간이 0이 되면 타이머 정지.
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
        
  }, [remainingTime]);

  const verifyCode = async () => {

    if (isVerified) {

      if (isMobile) {
        alert('이미 이메일 인증을 완료하셨습니다.');
      } else {
        toast('이미 이메일 인증을 완료하셨습니다.');
      }

    }else{

      setLoading(true);
      const response = await axiosInstance
        .post(`/user/verifyEmail/confirm?email=${debounceValue[0]?.trim()}@${debounceValue[1]?.trim()}&code=${code}`)
        .finally(() => setLoading(false));
      if (response.status === 200) {
        if (response.data.data === '코드가 일치합니다.') {
          setIsVerified(true);
	  setRemainingTime(0);
          if (isMobile) {
            alert('이메일 인증이 완료되었습니다.');
          } else {
            toast('이메일 인증이 완료되었습니다.');
          }
	}else{
          if (isMobile) {
            alert('인증 코드를 다시 확인해주세요.');
          } else {
            toast('인증 코드를 다시 확인해주세요.');
          }
	}
      }
    }
  };

  const formatTime =  () => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }

  const options = useMemo(
    () => [
      { value: 'naver.com', label: 'naver.com' },
      { value: 'hanmail.net', label: 'hanmail.net' },
      { value: 'daum.net', label: 'daum.net' },
      { value: 'nate.com', label: 'nate.com' },
      { value: 'gmail.com', label: 'gmail.com' },
      { value: 'yahoo.com', label: 'yahoo.com' },
      { value: '직접입력', label: '직접입력' },
    ],
    []
  );

  const customStyles = useMemo(
    () => ({
      option: (provided: any, state: any) => ({
        ...provided,
      }),
      control: (provided: any) => ({
        ...provided,
        width: 254,
        height: 48,
        border: '1px solid  #cfd9fe',
        borderRadius: 6,
        textIndent: 5,
        outline: 'none',
        boxShadow: 'none',
        '&:hover': {
          outline: 'none',
          borderColor: '#0A2996',
        },
        '&:focus-within': {
          outline: 'none',
          borderColor: '#0A2996',
        },
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: '#84AED3',
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        marginTop: -20,
      }),
      placeholder: (defaultStyles: any, state: any) => ({
        ...defaultStyles,
        color: '#84AED3',
        fontSize: 16,
        fontWeight: 400,
      }),
      input: (defaultStyles: any) => ({
        ...defaultStyles,
        marginLeft: 7,
        color: '#84AED3',
      }),
    }),
    []
  );

  const onChangeRecap = (value: any) => {
    setIsCap(value);
    console.log('Captcha value:', value);
  };
  const onBackClick = () => {
    navigation('/');
  };
  const onValid = async (validForm: any) => {
    console.log(validForm);
    if (loading) return;
    if (
      curEmail?.email?.trim().length === 0 ||
      curEmail?.backEmail?.trim().length === 0
    ) {
      if (isMobile) {
        alert('이메일을 다시 확인해주세요.');
      } else {
        toast('이메일을 다시 확인해주세요.');
      }
      return;
    }
    if (!canEmailUse) {
      if (isMobile) {
        alert('이메일을 다시 확인해주세요.');
      } else {
        toast('이메일을 다시 확인해주세요.');
      }
      return;
    }
    if (!isVerified) {
      if (isMobile) {
        alert('이메일 인증을 진행해주세요.');
      } else {
        toast('이메일 인증을 진행해주세요.');
      }
      return;
    }
    if (curNickname?.trim().length === 0) {
      if (isMobile) {
        alert('닉네임을 다시 확인해주세요.');
      } else {
        toast('닉네임을 다시 확인해주세요.');
      }
      return;
    }
    if (!canNicknameUse) {
      if (isMobile) {
        alert('닉네임을 다시 확인해주세요.');
      } else {
        toast('닉네임을 다시 확인해주세요.');
      }
      return;
    }
    if (validForm.password.trim().length === 0) {
      if (isMobile) {
        alert('비밀번호를 다시 확인해주세요.');
      } else {
        toast('비밀번호를 다시 확인해주세요.');
      }
      return;
    }
    if (validForm.password.trim() !== validForm.rePassword.trim()) {
      if (isMobile) {
        alert('비밀번호를 다시 확인해주세요.');
      } else {
        toast('비밀번호를 다시 확인해주세요.');
      }
      return;
    }
    if (
      /[`\-=\\\[\];',\./~!@#\$%\^&\*\(\)_\+|\{\}:"<>\?]/g.test(validForm.password.trim())
    ) {
      if (isMobile) {
        alert(
          '비밀번호는 특수문자를 제외하고 입력해주세요.'
        );
      } else {
        toast(
          '비밀번호는 특수문자를 제외하고 입력해주세요.'
        );
      }
      return;
    }
    if (
      !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(validForm.password.trim())
    ) {
      if (isMobile) {
        alert(
          '비밀번호는 최소 8자, 하나의 문자 및 하나의 숫자를 포함해주세요.'
        );
      } else {
        toast(
          '비밀번호는 최소 8자, 하나의 문자 및 하나의 숫자를 포함해주세요.'
        );
      }
      return;
    }
    if (isCap?.length === 0 || isCap === null) {
      if (isMobile) {
        alert("'로봇이 아닙니다'를 체크해주세요.");
      } else {
        toast("'로봇이 아닙니다'를 체크해주세요.");
      }
      return;
    }
    setLoading(true);
    const response = await axiosInstance
      .post('/user', {
        email: validForm.email.trim() + '@' + validForm.backEmail.trim(),
        password: validForm.password.trim(),
        nickname: validForm.nickname.trim(),
        isGoogleLogin: false,
        recaptchaCode: isCap,
      })
      .finally(() => setLoading(false));
    console.log(response);
    if (response.status === 200) {
      setIsModalOpen(true);
    }
  };
  const onConfirmClick = () => {
    setIsModalOpen(false);
    dispatch(
      settingSlice.actions.setLastPageBeforeLogin({
        lastPageBeforeLogin: curLocation,
      })
    );
    navigation('/login');
  };

  const onDebounceCall = async () => {
    if (
      debounceValue[0]?.trim().length > 0 &&
      debounceValue[1]?.trim().length > 0 &&
      (curEmail.email !== debounceValue[0] ||
        curEmail.backEmail !== debounceValue[1])
    ) {
      // 메일주소 변경되면 이메일 인증 초기화
      setIsVerifySend(false);
      setIsVerified(false);
      setCode('');
      // api호출
      const response = await axiosInstance.get(
        `/user/duplicate/email?email=${debounceValue[0]?.trim()}@${debounceValue[1]?.trim()}`
      );
      console.log(response);
      if (response.status === 200) {
        if (response.data.data === '사용가능한 이메일입니다.') {
          setCanEmailUse(true);
        } else {
          setCanEmailUse(false);
        }
      }
    }

    // 닉네임
    if (
      debounceValue[2]?.trim().length > 0 &&
      curNickname !== debounceValue[2]
    ) {
      // api호출
      console.log(debounceValue);

      const response = await axiosInstance.get(
        `/user/duplicate/nickname?nickname=${debounceValue[2]?.trim()}`
      );
      console.log(response);
      if (response.status === 200) {
        if (response.data.data === '사용가능한 닉네임입니다.') {
          setCanNicknameUse(true);
        } else {
          setCanNicknameUse(false);
        }
      }
    }
  };

  useEffect(() => {
    setCurEmail({
      email: debounceValue[0]?.trim(),
      backEmail: debounceValue[1]?.trim(),
    });
    setCurNickname(debounceValue[2]?.trim());
    onDebounceCall();
  }, [debounceValue]);

  return (
    <>
      <Container>
        <div
          style={{ display: 'flex' }}
          className={cls(isMobile ? 'mr-[145px]' : 'ml-[145px]')}
        >
          <ContentWrap>
            <Title>계정 생성하기</Title>
            <SubTitleWrap>
              {`아큐피디아에 오신 것을 환영합니다.
                아큐피디아에 가입해 자유롭게 문서를 편집/열람해보세요.`}
            </SubTitleWrap>
            <form onSubmit={handleSubmit(onValid)}>
              <div style={{ display: 'flex', width: '1000px' }}>
                <InputWrap className="email">
                  <span>E-mail 입력하기</span>
                  <input
                    style={{ marginLeft: 30 }}
                    {...register('email', { required: true })}
                    required
                  ></input>
                  <span className="at">@</span>
                </InputWrap>
                {isDirect ? (
                  <input
                    {...register('backEmail', { required: true })}
                    type="text"
                    className="text-[#84AED3] w-[254px] h-[50px] border-[#C1D6E9] border-solid border-2 rounded-md focus:outline-none indent-4"
                  />
                ) : (
                  <Controller
                    name="backEmail"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value, ref } }) => {
                      return (
                        <CreatableSelect
                          ref={ref}
                          onChange={(val) => {
                            if (val?.value === '직접입력') {
                              setIsDirect(true);
                              return;
                            }
                            onChange(val?.value);
                          }}
                          options={options}
                          styles={customStyles}
                          placeholder={'선택하세요.'}
                          components={{ IndicatorSeparator: () => null }}
                          formatCreateLabel={(value) =>
                            `${"'" + value + "'"} 선택`
                          }
                        />
                      );
                    }}
                  />
                )}
                {curEmail.backEmail &&
                curEmail.backEmail.trim().length > 0 &&
                curEmail.email &&
                curEmail.email.trim().length > 0 ? (
                  <div className="mb-6 ml-5 flex items-center">
                    {canEmailUse ? (
                      <>
                        <CheckIcon className="h-6 text-green-400" />
                        <span className="text-[#74828F] opacity-60">
                          &nbsp;사용가능한 메일입니다.
                        </span>
                      </>
                    ) : (
                      <>
                        <XIcon className="h-6 text-red-400" />
                        <span className="text-[#74828F] opacity-60">
                          &nbsp;이미 등록된 메일입니다.
                        </span>
                      </>
                    )}
                  </div>
                ) : null}
              </div>

              {curEmail.backEmail &&
                curEmail.backEmail.trim().length > 0 &&
                curEmail.email &&
                curEmail.email.trim().length > 0 &&
		canEmailUse ? (
                <>
                  <div
                    style={{
                      paddingLeft:'171px',                      
                    }}>
		    <InputWrap>
                      <input
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        readOnly={isVerified || !isVerifySend ? true : false}
                        style={{
                          marginRight:'5px',              
                        }}
                      ></input>
                      { isVerifySend ? (
                        <button type="button"
                          onClick={verifyCode}
                          style={{
                            backgroundColor:'#b4bfc9',
                            paddingLeft:'20px',   
                            paddingRight:'20px',  
                            height:'48px', 
                            lineHeight:'48px', 
                            borderRadius:'6px', 
                            color:'#fff',              
                          }}
                      >{ isVerified ? '인증완료' : '인증하기'}</button>
                      ) : (
                        <button type="button"
                          onClick={sendVerificationCode}
                          style={{
                            backgroundColor:'#b4bfc9',
                            paddingLeft:'20px',   
                            paddingRight:'20px',  
                            height:'48px', 
                            lineHeight:'48px', 
                            borderRadius:'6px', 
                            color:'#fff',              
                          }}
                        >이메일 인증</button>
                      )}
		      { isVerifySend && !isVerified ? formatTime() : '' }
                    </InputWrap>
                  </div>
                </>
              ) : (
                <>
                </>
              )}

              <InputWrap>
                <span>닉네임 입력하기</span>
                <input
                  {...register('nickname', {
                    required: true,
                    maxLength: 12,
                  })}
                  maxLength={12}
                  required
                ></input>
                <IoAlertCircleOutline className="alert" />
                <span className="idAlert">
                  12자 이내의 한글, 영어, 숫자만 가능
                </span>
                {curNickname && curNickname.trim().length > 0 ? (
                  <div className="mb-6 ml-[73px] flex items-center h-full mt-auto">
                    {canNicknameUse ? (
                      <>
                        <CheckIcon className="h-7 text-green-400" />
                        <span
                          style={{
                            fontSize: 15,
                            display: 'flex',
                            width: '100%',
                            opacity: '65%',
                            color: '#74828F',
                            fontWeight: 400,
                            letterSpacing: '-0.25px',
                          }}
                        >
                          &nbsp;사용가능한 닉네임입니다.
                        </span>
                      </>
                    ) : (
                      <>
                        <XIcon className="h-7 text-red-400" />
                        <span
                          style={{
                            fontSize: 15,
                            display: 'flex',
                            width: '100%',
                            opacity: '65%',
                            color: '#74828F',
                            fontWeight: 400,
                            letterSpacing: '-0.25px',
                          }}
                        >
                          &nbsp;이미 등록된 닉네임입니다.
                        </span>
                      </>
                    )}
                  </div>
                ) : null}
              </InputWrap>
              <InputWrap>
                <span>Password 입력하기</span>
                <input
                  {...register('password', { required: true })}
                  type="password"
                  required
                ></input>
                <IoAlertCircleOutline className="alert" />
                <span className="idAlert">
                  최소 8자, 하나의 문자 및 하나의 숫자를 포함해주세요.
                </span>
              </InputWrap>
              <InputWrap>
                <span>Password 확인</span>
                <input
                  {...register('rePassword', { required: true })}
                  type="password"
                  required
                ></input>
              </InputWrap>

              <PolicyWrap>
                <input
                  {...register('checkbox', { required: true })}
                  style={{
                    width: '22px',
                    height: '22px',
                    marginRight: '7px',
                    marginLeft: '0px',
                  }}
                  required
                  type={'checkbox'}
                />
                <span style={{ fontWeight: 300 }}>
                  <a
                    href="/terms"
                    className="highlight cursor-pointer font-bold"
                  >
                    이용약관
                  </a>{' '}
                  및{' '}
                  <a
                    href="/privacy"
                    className="highlight cursor-pointer font-bold"
                  >
                    개인정보취급방침
                  </a>
                  을 읽었으며 이에 동의합니다.
                </span>
              </PolicyWrap>
              <ReCAPTCHA
                className="recap"
                sitekey={process.env.REACT_APP_RECAP_API_KEY!!}
                onChange={(value) => onChangeRecap(value)}
              />
              <ButtonWrap>
                <Confirm className="confirm">
                  {loading ? <ClipLoader size={20} color="white" /> : '확 인'}
                </Confirm>
                <Cancel onClick={onBackClick} className="cancel">
                  돌아가기
                </Cancel>
              </ButtonWrap>
            </form>
          </ContentWrap>
          {/* <SideWrap>
            <SideComponent />
          </SideWrap> */}
        </div>
      </Container>
      <Modal
        ariaHideApp={false}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 999,
          },
          content: {
            width: '465px',
            height: '260px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            flexDirection: 'column',
          },
        }}
        isOpen={isModalOpen}
      >
        <p className="text-xl text-[#54595E] font-semibold">
          아큐피디아 가입을 환영합니다!
        </p>
        <div className="text-center mt-2 whitespace-pre-line text-sm text-[#54595e] text-opacity-80">
          <p>문서편집 권한은 한의사, 한의대생 회원에게 있습니다.</p>
          <p>한의사, 한의대생 등급으로 변경을 원하시면</p>
          <p>
            <span className="text-[#188aef] font-medium">
              admin@acupedia.net
            </span>
            으로 하단의 내용을 기입하여{' '}
          </p>
          <p>
            <span className="text-[#fb6c05] font-medium">추가 인증 자료</span>를
            보내주시길 바랍니다.
          </p>
          <p>(닉네임, 가입 이메일, 면허증/학생증 사본)</p>
        </div>
        <div
          onClick={onConfirmClick}
          className="cursor-pointer rounded-md mt-6 w-[385px] h-11 bg-[#183BB7] text-white flex justify-center items-center text-sm"
        >
          확인
        </div>
      </Modal>
    </>
  );
}

export default CreateAccount;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${isBrowser ? '' : '1440px'};

  .highlight {
    color: #05ccd9;
  }
  .email {
    display: inline;
  }
  .education {
    margin-top: 47px;
  }
  .recap {
    margin-top: 53px;
  }
  .confirm {
    background-color: ${(props) => props.theme.main};
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cancel {
    background-color: #b4bfc9;
  }
  .at {
    color: #74828f;
    font-size: 18px;
    font-weight: 300;
    margin-left: 5px;
    margin-right: 5px;
  }
  .idAlert {
    font-size: 13px;
    font-weight: 300;
    color: #74828f;
    opacity: 0.6;
    width: auto;
    margin-top: 2px;
  }
  .alert {
    color: #74828f;
    opacity: 0.6;
    margin-right: 5px;
    margin-left: 5px;
  }
`;
const ContentWrap = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;
const SideWrap = styled.div`
  margin-top: 190px;
  margin-left: 298px;
`;
const Title = styled.span`
  font-size: 54px;
  font-weight: 600;
  color: ${(props) => props.theme.fontColor};
`;
const SubTitleWrap = styled.div`
  margin-top: 5px;
  width: 600px;
  height: 30px;
  margin-bottom: 111px;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  align-items: center;
  font-weight: 300;
  color: ${(props) => props.theme.fontColor};
  white-space: pre-line;
`;
const InputWrap = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 23px;
  span {
    font-size: 18px;
    font-weight: 300;
    width: 157px;
    color: ${(props) => props.theme.fontColor};
    margin-right: 14px;
    letter-spacing: -0.5px;
  }
  input {
    width: 250px;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #cfd9fe;
    text-indent: 16px;

    ::placeholder {
      color: #acb3b9;
      font-size: 14px;
      font-weight: 300;
    }

    &:focus {
      outline: none;
      border-color: ${(props) => props.theme.main};
    }
  }
`;
const PolicyWrap = styled.div`
  margin-top: 53px;
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 600;
    color: #9ca3af;
  }
`;
const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 52px;
  justify-content: flex-end;
  height: 48px;
`;
const Confirm = styled.button`
  width: 120px;
  color: white;
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 1.1px;
  border-radius: 4px;
  cursor: pointer;
`;
const Cancel = styled.div`
  width: 120px;
  color: white;
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 1.1px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;


