import React from 'react';
import styled from 'styled-components';
import terms from 'components/terms';

function Terms() {
  return (
    <Container>
      <p style={{ fontSize: '25px' }}>이용약관</p>
      <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: terms }} />
    </Container>
  );
}

export default Terms;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 16px;
  margin-bottom: 50px;
`;
