import { HistoryItem } from 'components';
import { wikiApi } from 'libs/api';
import { cls } from 'libs/utils';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import settingSlice from 'slices/setting';
import { useAppDispatch } from 'store';

function WikiHistory() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  const curIndex = +location?.search?.split('?')[1].split('&')[1].split('=')[1];
  const title = decodeURI(location?.search?.split('?')[1].split('&')[0].split('=')[1]);
  const curLocation = location.pathname + location.search;
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem('jwtToken'));
  const [MAX_NUM, setMAX_NUM] = useState(0);
  const { data, isLoading } = useQuery(
    ['history', title, curIndex - 1, jwtToken],
    wikiApi.wikiHistoryAll,
    {
      onSuccess: (data) => setMAX_NUM(Math.floor(data?.data.totalCount / 50) + 1)
    }
  );
  console.log(data);

  const onReadClick = () => {
    navigation({
      pathname: '/wikiDetail',
      search: `?title=${title}`
    });
  };
  const onEditClick = () => {
    if (jwtToken) {
      navigation({
        pathname: '/wikiEdit',
        search: `?title=${title}`
      });
    } else {
      dispatch(
        settingSlice.actions.setLastPageBeforeLogin({ lastPageBeforeLogin: curLocation })
      );
      navigation('/login');
    }
  };
  const onLeftClick = () => {
    if (curIndex === 1) {
      return;
    }
    navigation({
      search: `?title=${title}&index=${curIndex - 1}`
    });
  };
  const onRightClick = () => {
    if (curIndex === MAX_NUM) {
      return;
    }
    navigation({
      search: `?title=${title}&index=${curIndex + 1}`
    });
  };

  const refreshJwtToken = () => {
    setJwtToken(sessionStorage.getItem('jwtToken'));
  };

  useEffect(() => {
    window.addEventListener('storage', refreshJwtToken);

    return () => {
      window.removeEventListener('storage', refreshJwtToken);
    };
  }, []);

  return isLoading ? null : (
    <div className="w-[944px] min-h-[1600px] ">
      <div className="h-[137px] flex justify-between">
        <div className="w-[510px] h-fit border-l-4 border-[#0F43F9] pl-4">
          <span className="tracking-[-2px] text-[25px] text-[#182F43] font-semibold">
            {title.replace(/ /g, '\u00a0')}
          </span>
        </div>
        <div className="w-[424px] h-[130px]">
          <div className="flex justify-between h-[44px]">
            <span
              onClick={onReadClick}
              className="cursor-pointer text-[#84AED3] rounded-l-lg flex-1 flex justify-center items-center border-[#84AED3] border-solid border-2"
            >
              읽기
            </span>
            <span
              onClick={onEditClick}
              className="text-[#84AED3] cursor-pointer flex-1 flex justify-center items-center border-[#84AED3] border-solid border-2"
            >
              편집
            </span>
            <span className="rounded-r-lg bg-[#3F69FA] text-white flex-1 flex justify-center items-center border-[#84AED3] border-solid border-2">
              히스토리
            </span>
          </div>
        </div>
      </div>
      <div className="w-[140px] h-[29px] bg-[#EBF2F8] mb-7 flex items-center">
        <div
          onClick={onLeftClick}
          className={cls(
            curIndex === 1 ? 'opacity-20' : 'cursor-pointer',
            'flex-1 justify-center flex'
          )}
        >
          {'< Prev'}
        </div>
        <div className="w-[1px] bg-[#74828F] h-full bg-opacity-50" />
        <div
          onClick={onRightClick}
          className={cls(
            curIndex === MAX_NUM ? 'opacity-20' : 'cursor-pointer',
            'flex-1 justify-center flex'
          )}
        >
          {'Next >'}
        </div>
      </div>
      {/* 히스토리 내역 */}
      <div className="space-y-[20px] ">
        {data.data.wikiHistoryResponses.map((item: any, index: number) => {
          return (
            <HistoryItem
              key={index}
              index={index}
              curLocation={curLocation}
              curIndex={curIndex}
              title={title}
              item={item}
            />
          );
        })}
      </div>

      <div className="w-[140px] h-[29px] bg-[#EBF2F8] mt-7 mb-[207px] flex items-center">
        <div
          onClick={onLeftClick}
          className={cls(
            curIndex === 1 ? 'opacity-20' : 'cursor-pointer',
            'flex-1 justify-center flex'
          )}
        >
          {'< Prev'}
        </div>
        <div
          onClick={onRightClick}
          className={cls(
            curIndex === MAX_NUM ? 'opacity-20' : 'cursor-pointer',
            'flex-1 justify-center flex'
          )}
        >
          {'Next >'}
        </div>
      </div>
    </div>
  );
}

export default WikiHistory;
