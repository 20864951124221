import React from 'react';
import styled from 'styled-components';
import privacy from 'components/privacy';

function Privacy() {
  return (
    <Container>
      <p style={{ fontSize: '25px' }}>개인정보처리방침</p>
      <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: privacy }} />
    </Container>
  );
}

export default Privacy;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 16px;
  margin-bottom: 50px;
`;
