// import 'github-markdown-css';
import { useLocation, useNavigate } from 'react-router-dom';
import { LibraryRule } from 'components';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { libraryApi } from 'libs/api';
import dayjs from 'dayjs';
// import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/react-editor';
import { useAppDispatch } from 'store';
import settingSlice from 'slices/setting';
import styled from 'styled-components';
import LibraryComments from 'components/LibraryComments';

function LibraryDetail() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  const authority = sessionStorage.getItem('authority');
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem('jwtToken'));
  const curLocation = location.pathname + location.search;
  const title = decodeURIComponent(
    location?.search?.split('?')[1].split('&')[0].split('=')[1]
  );

  const {
    data: data,
    refetch: detailRefetch,
    isFetching: isLoading,
  } = useQuery(['libraryDetail', title], libraryApi.libraryDocument);
  console.log(data);

  const onEditClick = () => {
    if (jwtToken) {
      navigation({
        pathname: '/libraryEdit',
        search: `?title=${title}`,
      });
    } else {
      dispatch(
        settingSlice.actions.setLastPageBeforeLogin({
          lastPageBeforeLogin: curLocation,
        })
      );
      navigation('/login');
    }
  };

  const refreshJwtToken = () => {
    setJwtToken(sessionStorage.getItem('jwtToken'));
  };

  useEffect(() => {
    window.addEventListener('storage', refreshJwtToken);

    return () => {
      window.removeEventListener('storage', refreshJwtToken);
    };
  }, []);

  return (
    <div className="w-[944px] min-h-[2000px]">
      {isLoading ? null : (
        <>
          <div className="w-full mb-[10px]">
            <p className="text-[20px] font-semibold tracking-tighter text-[#0A2996] ">
              · 학술 자료庫
            </p>
          </div>
          <div className="h-[137px] flex justify-between">
            <div className="w-[510px] h-fit border-l-4 border-[#0F43F9] pl-4 ">
              <span className="tracking-[-2px] text-[25px] text-[#182F43] font-semibold">
                {title.replace(/ /g, '\u00a0')}
              </span>
            </div>
            {data?.status === 'BAD_REQUEST' ? (
              <div className="w-[424px] h-[130px]"></div>
            ) : (
              <>
                {authority === 'ADMIN' ? (
                  <div className="w-[424px] h-[130px]">
                    <div className="flex justify-between h-[44px]">
                      <span className="rounded-l-lg bg-[#3F69FA] text-white flex-1 flex justify-center items-center border-[#84AED3] border-solid border-2">
                        읽기
                      </span>
                      <span
                        onClick={onEditClick}
                        className="text-[#84AED3] cursor-pointer flex-1 flex justify-center items-center border-[#84AED3] border-solid border-2"
                      >
                        편집
                      </span>
                    </div>
                    {isLoading ? null : (
                      <div className="flex flex-col h-[86px] justify-evenly ml-2">
                        <p className="flex">
                          <span className="font-semibold w-[100px] mr-8 text-[14px]">
                            최종 편집자
                          </span>
                          <span className="mr-5 text-[14px]">
                            {data.data.nickname}
                          </span>
                          <span className="text-[#3F69FA] text-[14px]">
                            {data.data.authority === 'ADMIN'
                              ? '관리자'
                              : data.data.authority === 'DOCTOR'
                              ? '한의사'
                              : data.data.authority === 'STUDENT'
                              ? '한의대생'
                              : '일반회원'}
                          </span>
                        </p>
                        <p>
                          <span className="font-semibold w-[100px] mr-[46px] text-[14px]">
                            최종 편집일시
                          </span>
                          <span className="text-[14px]">
                            {dayjs(data.data.lastModifiedAt).format(
                              'YYYY-MM-DD HH:mm:ss'
                            )}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}
              </>
            )}
          </div>
          {data?.status === 'BAD_REQUEST' ? (
            <div className="">
              <span className="text-[#9aa6b0] text-[22px] font-light mr-[100px]">
                해당 항목으로 생성된 학술 자료庫 항목이 없습니다.
              </span>
            </div>
          ) : null}
          {isLoading ? null : (
            <Div>
              <div className="pt-[30px] " data-color-mode="light">
                <Viewer initialValue={data.data.content} />
              </div>
            </Div>
          )}
          <>
            <div className="mt-[118px] mb-[78px] w-full">
              <LibraryRule />
            </div>
            <LibraryComments title={title} />
          </>
        </>
      )}
    </div>
  );
}

export default LibraryDetail;

const Div = styled.div`
  overflow: auto;
`;
