import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { PaginationBox, SearchItem, WikiItem } from 'components';
import { searchApi } from 'libs/api';
import { cls } from 'libs/utils';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

const LIMIT = 20;

function Wiki() {
  const navigation = useNavigate();
  const location = useLocation();
  const [curPass, setCurPass] = useState(
    Math.floor((+location?.search?.split('?')[1].split('=')[1] - 1) / LIMIT)
  );

  const [MAX_NUM, setMAX_NUM] = useState(0);
  const word = decodeURIComponent(
    location?.search?.split('?')[1].split('&')[0].split('=')[1]
  );
  const offset = +location?.search?.split('?')[1].split('&')[1].split('=')[1];
  const { data, isLoading } = useQuery(
    ['searchWiki', word, (+offset - 1) * LIMIT],
    searchApi.searchWiki,
    {
      onSuccess: (data) =>
        setMAX_NUM(
          Math.floor(
            data?.data.totalCount % LIMIT === 0
              ? data?.data.totalCount / LIMIT
              : data?.data.totalCount / LIMIT + 1
          )
        )
    }
  );
  console.log(data);

  const onNumClick = (index: number) => {
  
    if (index > MAX_NUM) return;
  
    navigation({
      search: `?search=${word}&index=${index}`,
    });
  };

  const onLeftClick = () => {
    if (offset === 1) {
      return;
    }
    navigation({
      search: `?search=${word}&index=${offset - 1}`
    });
  };
  const onRightClick = () => {
    if (offset === MAX_NUM) {
      return;
    }
    navigation({
      search: `?search=${word}&index=${offset + 1}`
    });
  };

  useEffect(() => {
    setCurPass(Math.floor((offset - 1) / 8));
    if (location.key === 'default') {
      // navigation(-1);
    }
  }, [location]);

  return (
    <div className="min-h-[1600px]">
      <div className=" w-[1000px] space-y-4 flex flex-col">
        {isLoading ? null : (
          <SearchItem title="위키항목" subtitle="Wiki">
            <div className="space-y-[10px]">
              <div className="h-[35px] flex items-center mt-4 bg-[#F4F4F4] border-t-[#C4C4C4] border-t-[1px] border-b-[#C4C4C4] border-b-[1px]">
                <div className="text-[15px] font-bold w-[300px]">
                  <span className=" ">
                    <span className="ml-[15px] text-[15px]">제목</span>
                  </span>
                </div>
                <span className="w-[225px] text-[15px] font-bold">수정자</span>
                <span className="text-[15px] font-bold">수정시간</span>
              </div>
              {data.data.wikis.map((item: any, index: number) => {
                return <WikiItem key={index} item={item} />;
              })}
            </div>
          </SearchItem>
        )}
      </div>
      {!isNaN(MAX_NUM) && !isNaN(curPass) && !isNaN(offset) ? (
        <div className="flex justify-center mt-[100px] mb-[50px]">
          <PaginationBox
            MAX_NUM={MAX_NUM}
            curPass={curPass}
            offset={offset}
            onLeftClick={onLeftClick}
            onNumClick={onNumClick}
            onRightClick={onRightClick}
            limit={20}
          />
        </div>
      ) : null}
    </div>
  );
}

export default Wiki;
